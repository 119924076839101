import {useEffect} from 'react'
import { logo } from 'assets/index'
import useStyles from './styles'
import { Box, Button, Grid, Link, Typography } from 'components/UI'
import ArrowOutwardIcon from '@material-ui/icons/ArrowUpwardRounded'
import sideImage from 'components/pages/PublicPages/Maintenance/assets/maintenance-image-side.jpeg'
import {
    FallIcon,
    IconBanner,
    SpringIcon,
    SummerIcon,
    WinterIcon,
} from './components/icons'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'assets'
import { newOrderMaintenanceActions } from 'ducks/actions'
import { getNewOrderMaintenanceValue, getUser, isAuthorized } from 'ducks/selectors'
import { NewMaintenanceModalsContext } from '../../Modals/context'
import { useContext } from 'react'
import { CONTACT_ROLE, USER_TYPE } from 'helpers/constants'
import { isMobile } from 'react-device-detect'


const Onboarding = () => {
    const dispatch = useDispatch()
    const {
        dispatch: dispatchContext
    } = useContext(NewMaintenanceModalsContext)

    const clientType = useSelector(getNewOrderMaintenanceValue('clientType'))
    const authorized = useSelector(isAuthorized)
    const user = useSelector(getUser)

    const handleSubmit = () => {
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'redirectAfterLogin',
                value: false
              })
            )
        if (authorized) {
            if (CONTACT_ROLE.LISTING_AGENT === clientType || USER_TYPE.BROKER === user?.clientType) {
                dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "step", value: 0 }))
            } else {
                dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "step", value: -1 }))
            }
        } else {
            dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "step", value: -1 }))
        }
    }

    const classes = useStyles()

    return (
        <Box className={classes.root} aria-label="maintenance-account">
            <Box className={classes.content}>
                {!authorized && <img src={logo} alt="" style={{ width: '225px' }} />}
                <Box className={classes.banner} mt="1.56rem">
                    <Typography className={classes.bannerText}>
                        Get to know all the membership details!
                    </Typography>
                    <Box style={{ cursor: "pointer" }} onClick={() => {
                        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'FAQS' })
                    }}>
                        <Typography className={classes.bannerTextFAQ}>
                            FAQs! <ArrowOutwardIcon className={classes.icon} />
                        </Typography>
                    </Box>
                    <IconBanner className={classes.iconBanner} />
                </Box>
                <Typography className={classes.title}>
                    stress free homeownership is just a few clicks away!
                </Typography>

                <Grid style={authorized && isMobile ? { paddingBottom: "6rem" } : {}} container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Box className={classes.sideImageContainer}>
                                <img
                                    src={sideImage}
                                    alt="People at work"
                                    className={classes.sideImage}
                                />
                            </Box>
                            <Typography className={classes.sideTitle}>
                                The VIP annual membership includes:
                            </Typography>

                            <ul style={{ paddingLeft: '1rem' }}>
                                <li>
                                    <Typography className={classes.sideTitle}>
                                        Four seasonal Maintenance Services
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.sideTitle}>
                                        Four Home Health Assesments
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        className={classes.sideTitle}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 5,
                                            maxHeight: 16,
                                        }}
                                    >
                                        Welcome Home{' '}
                                        <Typography style={{ cursor: "pointer" }} onClick={() => {
                                            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                                            dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'GIFT_SET' })
                                        }} className={classes.sideLink}>
                                            Gift set{' '}
                                            <ArrowOutwardIcon
                                                className={classes.icon}
                                                htmlColor="#2F7BF7"
                                                style={{ paddingTop: 6 }}
                                            />
                                        </Typography>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.sideTitle}>
                                        Dedicated VIP Customer Care Team
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <MaintenanceCardSeason
                                    title="spring"
                                    data={[
                                        'Dryer vent and hood cleaning (1 dryer)',
                                        'Detection systems check (smoke, gas, C02, water)',
                                        'Air filter replacement (up to two)',
                                        'Home health assessment',
                                    ]}
                                    backgroundColor="#E6F7EE"
                                    icon={<SpringIcon />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MaintenanceCardSeason
                                    title="summer"
                                    data={[
                                        'HVAC light maintenance ',
                                        'Exhaust fan service (kitchen and up to three bathrooms)',
                                        'Air filter replacement (up to two)',
                                        'Home health assessment',
                                    ]}
                                    backgroundColor="#FFF9EA"
                                    icon={<SummerIcon />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MaintenanceCardSeason
                                    title="fall"
                                    data={[
                                        'Water heater maintenance for one water heater',
                                        'Sink trap cleaning',
                                        'Drain cleaning (kitchen and up to two bathtubs)',
                                        'Air filter replacement (up to two)',
                                        'Home health assessment',
                                    ]}
                                    backgroundColor="#F6F5EF"
                                    icon={<FallIcon />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MaintenanceCardSeason
                                    title="winter"
                                    data={[
                                        'HVAC light maintenance',
                                        'Gutter cleaning (up to two hours)',
                                        'Air filter replacement (up to two)',
                                        'Home health assessment',
                                    ]}
                                    backgroundColor="#EAF2FE"
                                    icon={<WinterIcon />}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={2}>
                            <Typography variant="body2">
                                The scope of the maintenance services is intended to cover
                                customary equipment and costs; any equipment or material that is
                                not customary may not be covered or may require additional fees.
                            </Typography>
                        </Box>
                        <Box className={classes.fixedButton} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                size='large'
                                className={classes.buttonNext}
                                onClick={handleSubmit}
                                // endIcon={<Avatar alt='' src={arrow} className={classes.icon} />}
                                endIcon={<icons.ArrowForward style={{ width: '1rem' }} />}
                            // fullWidth
                            >
                                Let’s Get Started
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Onboarding

export function MaintenanceCardSeason(props: {
    title: string
    data: string[]
    backgroundColor: string
    icon: JSX.Element
}) {
    const classes = useStyles()
    const { title, data, backgroundColor, icon } = props

    return (
        <Box className={classes.card} style={{ backgroundColor }}>
            <Typography className={classes.cardTitle}>{title}</Typography>
            <Box sx={{ paddingLeft: '1.5rem' }} component="ul" mt={2}>
                {data.map((desc, index) => (
                    <li key={index}>
                        <Typography variant="caption">{desc}</Typography>
                    </li>
                ))}
            </Box>
            <Box className={classes.cardIcon}>{icon}</Box>
        </Box>
    )
}
