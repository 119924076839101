import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      width: '100%',
      height: '100%',
      maxWidth: '650px',
      maxHeight: '400px',
      borderRadius: '16px',
    },
    content: {
      display: 'flex',
      gridTemplateRows: 'min-content auto',
      gap: '24px',
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
        gap: '12px',
      },
      padding: '0 24px 24px',
    },
    list: {
      color: 'var(--text-color)',
      minWidth: '125px',
      marginTop: '4px',
      [theme.breakpoints.down('sm')]: {
        columns: 2,
      },
    },
    close: {
      position: 'absolute',
      zIndex: 1,
      top: theme.spacing(1) / 3,
      right: theme.spacing(1) / 2,
    },
    button: {
      backgroundColor: "var(--green-700) !important",
      color: "white !important",
      borderRadius: "8px !important",
      textTransform: "inherit",
      fontFamily: "LatoNormal !important",
    },
    icon: {
      width: "1rem"
    },
    subtitle: {
      textAlign: "center",
    },
    contentBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "2rem",
      padding: "1rem",
    }
  })
)
