/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { FC, useState, useEffect, useCallback, ReactNode, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  ValuesComponent,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Tag,
  DatePicker,
  Autocomplete,
  ZeroResultView,
  TableSortLabel,
  TableFooter,
  Pagination,
  CircularProgress,
  Box,
} from '../../../UI'
import CachedIcon from '@material-ui/icons/Cached'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useStyles from './styles'
import { DesktopPage, PrivateLoader } from '../../../templates'
import {
  ESTIMATE_PUBLIC_STATUS,
  formatDateToUnixTimestamp,
  formatTimestamp,
  history,
} from '../../../../helpers'
import { configActions, estimatesActions } from '../../../../ducks/actions'
import {
  getConfigValue,
  getEstimates,
  getEstimatesIndicators,
} from '../../../../ducks/selectors'
import { Range } from '../../../UI/CustomUI/atoms/DatePicker/types'
import { EstimatesIndicators } from '../../../../ducks/estimates/types'
import { Estimate } from '../../../../ducks/types'
import { useClassName, useIsXsScreen } from '../../../../hooks'
import EstimateItem from './EstimateItem'
import SearchInput from '../../../UI/CustomUI/molecules/SearchInput'
import { debounce } from 'lodash'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const initialIndicators = {
  total: 0,
  inProgress: 0,
  expired: 0,
  completed: 0,
  approved: 0,
}

const statusOptions = [
  { key: 0, value: 'Any Status' },
  ...Object.keys(ESTIMATE_PUBLIC_STATUS).map((status, index) => {
    return {
      key: index + 1,
      value:
        ESTIMATE_PUBLIC_STATUS[status as keyof typeof ESTIMATE_PUBLIC_STATUS],
    }
  }),
]

interface EstimatesProps {
  shared?: boolean
}

const Estimates = ({ shared }: EstimatesProps) => {
  const classes = useStyles()
  const className = useClassName()
  const [xsScreen] = useIsXsScreen()
  const dispatch = useDispatch()
  const pushForward = history.usePushForward()
  const push = history.usePush()

  const estimateStatus = useSelector(getConfigValue('estimateStatus'))
  const estimatePagination = useSelector(getConfigValue('estimatePagination'))
  const initialStatus = estimateStatus ?? statusOptions[0]

  const [pageLoading, setPageLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(false)
  const [statsLoading, setStatsLoading] = useState(false)
  const [pageChange, setPageChange] = useState(false)

  const [status, setStatus] = useState<any>(initialStatus)
  const [dateRange, setDateRange] = useState<Range | null>(null)
  const [indicators, setIndicators] =
    useState<EstimatesIndicators>(initialIndicators)
  const [searchInput, setSearchInput] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const [sortBy, setSortBy] = useState<string>('createdOn')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [pageStart, setPageStart] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const estimates = useSelector(getEstimates())
  const estimatesIndicators = useSelector(
    getEstimatesIndicators(),
    shallowEqual
  )

  const fetchEstimates = useCallback(() => {
    setTableLoading(true)
    dispatch(
      estimatesActions.fetchEstimates(
        {
          startDate: dateRange?.startDate
            ? formatDateToUnixTimestamp(dateRange?.startDate)
            : undefined,
          endDate: dateRange?.endDate
            ? formatDateToUnixTimestamp(dateRange?.endDate)
            : undefined,
          status: status?.key !== 0 ? [status?.value] : undefined,
          start: pageStart,
          limit: rowsPerPage,
          sortBy,
          sortOrder,
          search,
          shared,
        },
        (_succ: boolean) => {
          setPageChange(false)
          setPageLoading(false)
          setTableLoading(false)
        }
      )
    )
  }, [
    dateRange,
    status,
    pageStart,
    rowsPerPage,
    sortBy,
    sortOrder,
    search,
    shared,
  ])

  const fetchEstimatesStats = useCallback(() => {
    setStatsLoading(true)
    dispatch(
      estimatesActions.fetchEstimatesStats(
        {
          startDate: dateRange?.startDate
            ? formatDateToUnixTimestamp(dateRange?.startDate)
            : undefined,
          endDate: dateRange?.endDate
            ? formatDateToUnixTimestamp(dateRange?.endDate)
            : undefined,
          status: status?.key !== 0 ? status?.value : undefined,
          search,
        },
        (_succ: boolean) => {
          setStatsLoading(false)
        }
      )
    )
  }, [dateRange, status, search])

  useEffect(() => {
    handleRefresh()
  }, [dateRange, status, fetchEstimates])

  useEffect(() => {
    setIndicators(
      (statsLoading || tableLoading) && !pageChange
        ? initialIndicators
        : estimatesIndicators
    )
  }, [statsLoading, tableLoading, estimatesIndicators.total])

  const handleSelect = (rangeSelected: Range) => {
    setDateRange(rangeSelected)
  }

  const handleRefresh = () => {
    fetchEstimates()
    fetchEstimatesStats()
    dispatch(
      configActions.setConfigValue({
        type: 'estimateDateRange',
        value: dateRange,
      })
    )
    dispatch(
      configActions.setConfigValue({ type: 'estimateStatus', value: status })
    )
  }

  const getNoResultTitle = (): ReactNode => {
    switch (status?.value) {
      case 'Any Status':
        return !dateRange?.endDate && !dateRange?.startDate
          ? 'You have no Estimates yet'
          : 'No Estimates for this range of dates'
      default:
        return (
          <p>
            No <b>{status?.value}</b> Estimates
          </p>
        )
    }
  }

  const getNoResultSharedTitle = (): ReactNode => {
    switch (status?.value) {
      case 'Any Status':
        return !dateRange?.endDate && !dateRange?.startDate
          ? 'You have no Shared Estimates yet'
          : 'No Shared Estimates for this range of dates'
      default:
        return (
          <p>
            No <b>{status?.value}</b> Shared Estimates
          </p>
        )
    }
  }

  const getNoResultDescription = (): ReactNode => {
    switch (status?.value) {
      case 'Any Status':
        return !dateRange?.endDate && !dateRange?.startDate
          ? 'Request your first Estimate to start!'
          : "Try selecting another range of dates or selecting 'All history'."
      default:
        return "Please select another status or 'Any Status' to see all the Estimates you have."
    }
  }

  const getNoResultSharedDescription = (): ReactNode => {
    switch (status?.value) {
      case 'Any Status':
        return !dateRange?.endDate && !dateRange?.startDate
          ? 'You will see Shared Estimates here!'
          : "Try selecting another range of dates or selecting 'All history'."
      default:
        return "Please select another status or 'Any Status' to see all the Shared Estimates you have."
    }
  }

  const handleSort = (columnName: string) => {
    const tempsortBy = sortBy
    setPageChange(true)
    setSortBy(columnName)
    setSortOrder(
      columnName === tempsortBy
        ? sortOrder === 'asc'
          ? 'desc'
          : 'asc'
        : 'desc'
    )
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageChange(true)
    setPageStart(newPage * rowsPerPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageChange(true)
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageStart(0)
  }

  const changeSearchFilter = useCallback(
    (searchInput: string) => setSearch(searchInput),
    []
  )
  const onSearchInputChange = useMemo(
    () => debounce(changeSearchFilter, 500),
    [searchInput]
  )
  const handleSearchInputChange = (event: any) => {
    setSearchInput(event.target.value)
    onSearchInputChange(event.target.value)
  }

  const getCounterValue = (value: number): number => {
    return estimates.length === 0 ? 0 : value
  }

  return (
    <DesktopPage
      title={
        <Typography variant='h4' className={classes.pageTitle}>
          {shared ? 'Shared Estimates' : 'Estimates'}
        </Typography>}
      customPadding='0' >
      <PrivateLoader loading={pageLoading}>
        <Grid container spacing={3} className={classes.root}>
          <Grid container item spacing={3}>
            <Grid item className={classes.filter}>
              <Typography variant="body1"> Date Range: </Typography>
              <DatePicker
                onChange={handleSelect}
                value={dateRange}
                allowRange
              />
            </Grid>
            <Grid item className={classes.filter}>
              <Autocomplete
                label="Status:"
                value={status}
                options={statusOptions}
                onChange={(value: any) => {
                  setStatus(value)
                }}
                className={classes.statusSelect}
              />
            </Grid>
            <Grid item className={classes.filter}>
              <Typography variant="body1"> Search:</Typography>
              <SearchInput
                value={searchInput}
                onChange={handleSearchInputChange}
              />
            </Grid>
            <Grid item className={classes.refresh}>
              <Button className={classes.iconButton} onClick={handleRefresh}>
                <CachedIcon fontSize='small' color='inherit' />
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.valueComponents}
          >
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title="Total"
                titleColor="primary"
                information="Total number of requested estimates"
                value={getCounterValue(indicators.total)}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title="In Progress"
                titleColor="info"
                information="Total number of estimates that are currently in progress. We’ll let you know when they’re ready!"
                value={getCounterValue(indicators.inProgress)}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title="Expired"
                titleColor="error"
                information="Total number of expired estimates. Estimates expire after 30 days."
                value={getCounterValue(indicators.expired)}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title="Completed"
                titleColor="success"
                information="Total number of completed estimates. These are ready for review and approval!"
                value={getCounterValue(indicators.completed)}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title="Approved"
                titleColor="approved"
                information="Total number of approved estimates. Check out the Jobs page to see their progress."
                value={getCounterValue(indicators.approved)}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.tableContainer}>
            <Grid item xs={12} className={classes.table}>
              <PrivateLoader
                loading={!pageChange && tableLoading}
                building="fragment"
              >
                <TableContainer
                  component={Paper}
                  className={
                    !xsScreen ? classes.tableComponent : classes.tableOff
                  }
                >
                  {pageChange && (
                    <Box className={classes.pageChange}>
                      <CircularProgress disableShrink size={86} thickness={4} />
                    </Box>
                  )}

                  {xsScreen && (
                    <Grid container item xs={12} spacing={3}>
                      {estimates.length > 0
                        ? estimates.map((estimate: Estimate) => (
                          <EstimateItem
                            key={estimate.id}
                            estimate={estimate}
                          />
                        ))
                        : null}
                      {estimates.length > 0 && (
                        <TableFooter>
                          <TableRow>
                            <Pagination
                              count={estimatePagination?.total || 0}
                              colspan={6}
                              rowsPerPage={rowsPerPage}
                              page={pageStart / rowsPerPage}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      )}
                    </Grid>
                  )}

                  {!xsScreen && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.cellHead}
                            style={{ width: '80px' }}
                          >
                            Ref
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                          >
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('address')}
                              direction={
                                sortBy === 'address' ? sortOrder : 'asc'
                              }
                            >
                              Address
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                            align="center"
                            style={{ width: '120px' }}
                          >
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('createdOn')}
                              direction={
                                sortBy === 'createdOn' ? sortOrder : 'asc'
                              }
                            >
                              Submitted
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                            align="center"
                            style={{ width: '120px' }}
                          >
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('deliveredOn')}
                              direction={
                                sortBy === 'deliveredOn' ? sortOrder : 'asc'
                              }
                            >
                              Delivered
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                            align="center"
                            style={{ width: '120px' }}
                          >
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('approvedOn')}
                              direction={
                                sortBy === 'approvedOn' ? sortOrder : 'asc'
                              }
                            >
                              Approved
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                            align="center"
                            style={{ width: '150px' }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                            align="center"
                            style={{ width: '120px' }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {estimates.length > 0
                          ? estimates.map((estimate: Estimate) => {
                            const {
                              id,
                              createdOn,
                              deliveredOn,
                              approvedOn,
                              dueOn,
                              publicStatus,
                              properties,
                            } = estimate
                            const { line_1, city, state } =
                              properties?.address || {}

                            return (
                              <TableRow key={id} className={classes.row}>
                                <TableCell className={`${classes.cell} ${classes.idCell}`}>
                                  <Typography className={classes.title}>
                                    {properties?.publicId}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <Typography className={classes.title}>
                                    {line_1}
                                  </Typography>
                                  <Typography className={classes.description}>
                                    {`${city}, ${state}`}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align="center"
                                >
                                  <Typography className={classes.title}>
                                    {formatTimestamp(createdOn, 'MM/DD/YYYY')}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align="center"
                                  style={{ position: 'relative' }}
                                >
                                  <Typography className={classes.title}>
                                    {deliveredOn
                                      ? formatTimestamp(
                                        deliveredOn,
                                        'MM/DD/YYYY'
                                      )
                                      : formatTimestamp(dueOn, 'MM/DD/YYYY')}
                                  </Typography>
                                  {!deliveredOn && (
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        position: 'absolute',
                                        right: 0,
                                        left: 0,
                                      }}
                                      className={className(
                                        classes.title,
                                        classes.expectedDeliveryLabel
                                      )}
                                    >
                                      (Expected)
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align="center"
                                >
                                  <Typography className={classes.title}>
                                    {approvedOn
                                      ? formatTimestamp(
                                        approvedOn,
                                        'MM/DD/YYYY'
                                      )
                                      : '-'}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} ${classes.status}`}
                                  align="center"
                                >
                                  <Tag type={publicStatus} />
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align="center"
                                >
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={
                                      publicStatus ===
                                      ESTIMATE_PUBLIC_STATUS.IN_PROGRESS ||
                                      publicStatus === "Ready_for_release" as ESTIMATE_PUBLIC_STATUS
                                    }
                                    className={classes.button}
                                    onClick={() => {
                                      pushForward(`${id}`)
                                    }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : null}
                      </TableBody>
                      {estimates.length > 0 && (
                        <TableFooter>
                          <TableRow>
                            <Pagination
                              count={estimatePagination?.total || 0}
                              colspan={6}
                              rowsPerPage={rowsPerPage}
                              page={pageStart / rowsPerPage}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      )}
                    </Table>
                  )}
                  {!shared && estimates.length < 1 && (
                    <ZeroResultView
                      title={getNoResultTitle()}
                      description={getNoResultDescription()}
                      buttonText="Get an Estimate"
                      showButton={
                        status?.value === 'Any Status' &&
                        !dateRange?.startDate &&
                        !dateRange?.endDate
                      }
                      onClick={() => {
                        push('estimate')
                      }}
                    />
                  )}

                  {shared && estimates.length < 1 && (
                    <ZeroResultView
                      title={getNoResultSharedTitle()}
                      description={getNoResultSharedDescription()}
                      buttonText="Get an Estimate"
                      showButton={false}
                    />
                  )}
                </TableContainer>
              </PrivateLoader>
            </Grid>
          </Grid>
        </Grid>
      </PrivateLoader>
    </DesktopPage >
  )
}

export default Estimates
