import { FC, useEffect, useState } from 'react'
import { Autocomplete, Grid, Typography } from '../../../../../../../UI'
import useStyles from './../../../styles'
import {
  getApprovalStep,
  getApprovalValues,
  getContactInEstimate,
  getContactIndexInEstimate,
  getEstimate,
  getUser,
  getUserIsHomeowner,
  getUserPrimaryEmail,
} from '../../../../../../../../ducks/selectors'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import { useDispatch, useSelector } from 'react-redux'
import SchedulerContact from '../../../components/SchedulerContact'
import Scheduling from '../../../components/Scheduling'
import PropertyStatus from '../../../components/PropertyStatus'
import SmsConsent from '../../../components/SmsConsent'
import AccessDetails from '../../../components/AccessDetails'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  FILE_TYPE,
  companyRoles,
  getContactRoleOpt,
  getDifferenceBetweenObjects,
  history,
  isEmpty,
  objectToUpdateRequest,
  validateEmail,
  validatePhone,
} from 'helpers/index'
import BooleanCheckbox from 'components/UI/CustomUI/molecules/BooleanCheckbox'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions } from 'ducks/actions'
import { EstimateContact, EstimateProperties, PunchlistFile } from 'ducks/types'
import { UpdateRequest } from 'api/types'
import { diff } from 'deep-object-diff'
import PayAtClose from '../../../components/PayAtClose'
import ListingAgentInformation from '../../../components/ListingAgentInformation'
import ListingAgreement from '../../../components/ListingAgreement'
import { EstimateType } from 'ducks/estimate/types'

const Step1Homeowner: FC = () => {
  const classes = useStyles()
  const [listingAgreementDocument, setListingAgreementDocument] = useState<
    PunchlistFile[]
  >([])
  const { setShowWarnings, setCanGoForward, showWarnings, saveNewValue } =
    useStepperContext()
  const push = history.usePushForward()
  const goBack = history.useGoBack()
  const dispatch = useDispatch()
  const estimate = useSelector(getEstimate())
  const user = useSelector(getUser)
  const userEmail = useSelector(getUserPrimaryEmail)
  const approvalStep = useSelector(getApprovalStep)
  const alreadyUpdatedListingAgreement = estimate?.properties?.files?.find(
    (file) => file.fileType === FILE_TYPE.LISTING_AGREEMENT
  )
  const fileIndex = estimate?.properties?.files?.findIndex(
    (file) => file.fileType === FILE_TYPE.LISTING_AGREEMENT
  )
  const contactInEstimateIndex = useSelector(
    getContactIndexInEstimate(userEmail)
  )

  const isHomewardCXSupport = [
    'cxsupport@test.com',
    'cxsupport@homeward.com',
  ].includes(userEmail)

  const {
    preferredCompletedOn,
    smsConsent,
    preferredStartDate,
    lockboxConsent,
    schedulerSelect,
    propertyToBeSold,
    scheduler,
    propertyUnderContract,
    closingDate,
    propertyListed,
    completedBeforeClosing,
    paymentOption,
    lockbox,
    accessDetails,
    role,
    agentIsHomeowner,
    listingAgent,
    agentAsHomeownerRepresentative,
  } = useSelector(getApprovalValues())

  const contactInEstimate = useSelector(getContactInEstimate(userEmail))

  const showPayAtClose = contactInEstimate?.role !== CONTACT_ROLE.HOME_BUYER

  const agentContact = estimate?.properties?.contacts?.find(
    (c) =>
      companyRoles.includes(c.role as CONTACT_ROLE) && c.email !== userEmail
  )

  const schedulerFromEstimateIndex = estimate?.properties?.contacts?.findIndex(
    (c) => c?.isScheduler
  )
  const schedulerFromEstimate = estimate?.properties?.contacts?.find(
    (c) => c?.isScheduler
  )

  //we don't show the payment option selector if the agent added to the estimate is the scheduler
  // https://punchlistusa.slack.com/archives/C06Q9FADVD5/p1718387043426099?thread_ts=1718386961.902459&cid=C06Q9FADVD5

  const showPayAtCompletionFlow =
    paymentOption === 'pay_at_completion' || agentIsHomeowner

  const isHomeowner = agentIsHomeowner || getUserIsHomeowner()

  const schedularContactValidation =
    schedulerSelect === 'other'
      ? !!scheduler?.role &&
        !!scheduler.firstName &&
        !!scheduler.lastName &&
        !!scheduler.email &&
        validateEmail(scheduler.email) &&
        !!scheduler.phone &&
        validatePhone(scheduler?.phone)
      : true

  const listingAgentValidation =
    agentAsHomeownerRepresentative !== 'no_selected'
      ? agentAsHomeownerRepresentative === 'listing_agent' || !agentIsHomeowner
        ? !!listingAgent?.companyName &&
          !!listingAgent?.firstName &&
          !!listingAgent?.lastName &&
          !!listingAgent?.email &&
          validateEmail(listingAgent?.email) &&
          !!listingAgent?.phone &&
          validatePhone(listingAgent?.phone)
        : agentAsHomeownerRepresentative === 'myself' &&
          !!listingAgreementDocument?.length
      : false

  const houseInformationValidation = () => {
    if (propertyToBeSold !== undefined) {
      if (propertyToBeSold) {
        if (propertyUnderContract !== undefined) {
          if (propertyUnderContract) {
            return (
              !isEmpty(closingDate?.toString()) &&
              completedBeforeClosing !== undefined &&
              completedBeforeClosing !== null
            )
          } else {
            return propertyListed !== undefined && propertyListed !== null
          }
        }
      } else {
        return true
      }
    }
  }

  const disableNext = !!estimate?.properties.contacts?.find(
    (contact) => contact.approvalFlowInProgress && contact.email !== userEmail
  )

  const flowValidation =
    typeof preferredStartDate !== 'undefined' &&
    typeof preferredCompletedOn !== 'undefined' &&
    !!smsConsent &&
    (lockbox || !!lockboxConsent) &&
    schedularContactValidation &&
    houseInformationValidation()

  const validate = () => {
    if (disableNext) return false

    if (paymentOption === 'pay_at_close') {
      if (agentIsHomeowner) {
        return (agentContact || listingAgentValidation) && flowValidation
      }
      return agentContact || listingAgentValidation
    }
    return flowValidation
  }

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanGoForward(validate() as boolean)
  }, [validate])

  useEffect(() => {
    if (alreadyUpdatedListingAgreement) {
      setListingAgreementDocument([alreadyUpdatedListingAgreement])
    }
  }, [alreadyUpdatedListingAgreement])

  const submitStep = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    updateEstimate()
  }

  const updateEstimate = () => {
    if (paymentOption === 'pay_at_close') {
      const agentIndex = estimate?.properties?.contacts?.findIndex(
        (c) => c.id === agentContact?.id
      )

      const payload: UpdateRequest = [
        { op: 'replace', path: '/properties/payAtClose', value: true },
        { op: 'replace', path: '/properties/propertyToBeSold', value: true },
      ]

      if (agentAsHomeownerRepresentative !== 'myself') {
        //remove the approvalFlowInProgress in case they went to next step and came back to select pay at close, only if they didn't select themselves as representative (they will then need to continue the flow)

        payload.push({
          op: 'replace',
          path: `/properties/contacts/${contactInEstimateIndex}/approvalFlowInProgress`,
          value: false,
        })
        payload.push({
          op: 'replace',
          path: `/properties/contacts/${contactInEstimateIndex}/approvalType`,
          value: APPROVAL_TYPE.REQUIRED,
        })
      } else if(!isHomewardCXSupport) {
        payload.push(
          {
            op: 'replace',
            path: `/properties/approvalStep`,
            value: approvalStep + 1,
          },
          {
            op: 'replace',
            path: `/properties/contacts/${contactInEstimateIndex}/approvalFlowInProgress`,
            value: true,
          }
        )
      }

      if (agentIndex === -1 || agentIsHomeowner) {
        //If there's no agent in the estimate we add it
        if (
          agentAsHomeownerRepresentative === 'listing_agent' ||
          !agentIsHomeowner
        ) {
          //If the agent selected that has a listing agent or the user is a homeowner we add the listing agent info.
          payload.push({
            op: 'add',
            path: `/properties/contacts/-`,
            value: {
              ...listingAgent,
              approvalType: APPROVAL_TYPE.REQUIRED,
              role: CONTACT_ROLE.LISTING_AGENT,
            },
          })
          payload.push({
            op: 'replace',
            path: `/properties/contacts/${contactInEstimateIndex}/role`,
            value: CONTACT_ROLE.HOMEOWNER,
          })
        } else {
          //If it's an agent and selected 'myself' as the representative we change the contact role to AGENT_HOMEOWNER
          payload.push({
            op: 'replace',
            path: `/properties/contacts/${contactInEstimateIndex}/role`,
            value: CONTACT_ROLE.AGENT_HOMEOWNER,
          })
          payload.push({
            op: 'replace',
            path: `/properties/contacts/${contactInEstimateIndex}/isPayer`,
            value: true,
          })
        }
      } else {
        payload.push({
          op: 'replace',
          path: `/properties/contacts/${agentIndex}/approvalType`,
          value: APPROVAL_TYPE.REQUIRED,
        })
      }

      if (agentAsHomeownerRepresentative === 'myself') {
        payload.push({
          op: alreadyUpdatedListingAgreement ? 'replace' : 'add',
          path: `/properties/files/${
            alreadyUpdatedListingAgreement ? fileIndex : '-'
          }`,
          value: {
            fileType: FILE_TYPE.LISTING_AGREEMENT,
            fileUrl: listingAgreementDocument[0]?.fileUrl,
            fileName: listingAgreementDocument[0]?.name,
            name: listingAgreementDocument[0]?.name,
          },
        })
      }

      let valuesToSend: Partial<EstimateProperties> = {}
      // const contactsPayload: UpdateRequest = []
      if (agentIsHomeowner) {
        valuesToSend = {
          preferredStartDate,
          preferredCompletedOn,
        }
        if (schedulerSelect === 'me') {
          // if the logged user is the scheduler we remove the scheduler from the estimate and add isScheduler to the user
          payload.push({
            op: 'replace',
            path: `/properties/contacts/${contactInEstimateIndex}/isScheduler`,
            value: true,
          })

          if (
            schedulerFromEstimateIndex !== -1 &&
            schedulerFromEstimate?.email !== userEmail
          ) {
            payload.push({
              op: 'replace',
              path: `/properties/contacts/${schedulerFromEstimateIndex}/isScheduler`,
              value: false,
            })
          }
        } else {
          if (schedulerFromEstimate?.id !== scheduler?.id) {
            //we remove the isScheduler flag from the previous scheduler
            payload.push({
              op: 'replace',
              path: `/properties/contacts/${schedulerFromEstimateIndex}/isScheduler`,
              value: false,
            })
          }
          // if other we need to remove the flag from the 'me' user if existed
          if (scheduler?.id) {
            const differences: any =
              schedulerFromEstimate && diff(schedulerFromEstimate, scheduler)

            const schedulerDifferences: UpdateRequest =
              getDifferenceBetweenObjects(
                differences,
                undefined,
                `properties/contacts/${schedulerFromEstimateIndex}`
              )

            if (!differences?.email) {
              payload.push(...schedulerDifferences)
            } else {
              delete scheduler.id
              delete scheduler.contactId
              //if the user is trying to edit the email for an already existing scheduler we need to remove the scheduler flag from the previous one and add the new contact
              payload.push({
                op: 'replace',
                path: `/properties/contacts/${schedulerFromEstimateIndex}/isScheduler`,
                value: false,
              })
              payload.push({
                op: 'add',
                path: `/properties/contacts/-`,
                value: { ...scheduler, isScheduler: true },
              })
            }
          } else {
            payload.push({
              op: 'add',
              path: `/properties/contacts/-`,
              value: { ...scheduler, isScheduler: true },
            })
          }
        }
      }
      if (propertyToBeSold) {
        valuesToSend = {
          ...valuesToSend,
          propertyUnderContract,
          closingDate,
          completedBeforeClosing,
          propertyListed,
          lockboxConsent,
          lockbox: lockbox ?? '',
          accessDetails,
          smsConsent,
          payAtClose: true,
        }
      }

      dispatch(
        estimateActions.updateEstimateByPath(
          [...payload, ...objectToUpdateRequest(valuesToSend, '/properties/')],
          (succ) => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )

            succ &&
              agentAsHomeownerRepresentative !== 'myself' &&
              push(`success`)
          }
        )
      )
    } else {
      let valuesToSend: Partial<EstimateProperties> = {
        preferredStartDate,
        preferredCompletedOn,
        closingDate,
        completedBeforeClosing,
        propertyToBeSold,
        lockbox: lockbox ?? '',
        accessDetails,
        smsConsent,
        lockboxConsent,
        approvalStep: approvalStep + 1,
        payAtClose: false,
      }

      if (propertyToBeSold) {
        valuesToSend = {
          ...valuesToSend,
          propertyUnderContract,
          closingDate,
          completedBeforeClosing,
          propertyListed,
        }
      } else {
        valuesToSend = {
          ...valuesToSend,
          propertyUnderContract: false,
          propertyListed: false,
          closingDate: null,
          payAtClose: false,
        }
      }

      const contactsPayload: UpdateRequest = []

      contactsPayload.push({
        op: 'replace',
        path: `/properties/contacts/${contactInEstimateIndex}/approvalFlowInProgress`,
        value: true,
      })

      if (agentIsHomeowner) {
        // if agent is homeowner, we need to update the role
        contactsPayload.push({
          op: 'replace',
          path: `/properties/contacts/${contactInEstimateIndex}/role`,
          value: role,
        })
      }

      if (schedulerSelect === 'me') {
        // if the logged user is the scheduler we remove the scheduler from the estimate and add isScheduler to the user
        contactsPayload.push({
          op: 'replace',
          path: `/properties/contacts/${contactInEstimateIndex}/isScheduler`,
          value: true,
        })

        if (
          schedulerFromEstimateIndex !== -1 &&
          schedulerFromEstimate?.email !== userEmail
        ) {
          contactsPayload.push({
            op: 'replace',
            path: `/properties/contacts/${schedulerFromEstimateIndex}/isScheduler`,
            value: false,
          })
        }
      } else {
        if (schedulerFromEstimate?.id !== scheduler?.id) {
          //we remove the isScheduler flag from the previous scheduler
          contactsPayload.push({
            op: 'replace',
            path: `/properties/contacts/${schedulerFromEstimateIndex}/isScheduler`,
            value: false,
          })
        }
        // if other we need to remove the flag from the 'me' user if existed
        if (scheduler?.id) {
          const differences: any =
            schedulerFromEstimate && diff(schedulerFromEstimate, scheduler)

          const schedulerDifferences: UpdateRequest =
            getDifferenceBetweenObjects(
              differences,
              undefined,
              `properties/contacts/${schedulerFromEstimateIndex}`
            )

          if (!differences?.email) {
            contactsPayload.push(...schedulerDifferences)
          } else {
            delete scheduler.id
            delete scheduler.contactId
            //if the user is trying to edit the email for an already existing scheduler we need to remove the scheduler flag from the previous one and add the new contact
            contactsPayload.push({
              op: 'replace',
              path: `/properties/contacts/${schedulerFromEstimateIndex}/isScheduler`,
              value: false,
            })
            contactsPayload.push({
              op: 'add',
              path: `/properties/contacts/-`,
              value: { ...scheduler, isScheduler: true },
            })
          }
        } else {
          contactsPayload.push({
            op: 'add',
            path: `/properties/contacts/-`,
            value: { ...scheduler, isScheduler: true },
          })
        }
      }

      dispatch(
        estimateActions.updateEstimateByPath(
          [
            ...contactsPayload,
            ...objectToUpdateRequest(valuesToSend, '/properties/'),
          ],
          (succ, estimate) => {
            if (succ && isHomewardCXSupport) {
              const contacts: EstimateContact[] =
                estimate?.properties?.contacts?.reduce(
                  (acc: any, curr: Partial<EstimateContact>) => {
                    if (curr.email === userEmail) {
                      acc = [
                        ...acc,
                        {
                          ...curr,
                          approvalType: APPROVAL_TYPE.APPROVED,
                          isPayer: true,
                        },
                      ]
                    } else {
                      acc = [...acc, curr]
                    }
                    return acc
                  },
                  []
                )
              const payload: Partial<EstimateType> = {
                id: estimate?.id,
                properties: {
                  address: estimate?.properties?.address,
                  payAtClose: estimate?.properties?.payAtClose,
                  accessDetails: estimate?.properties?.accessDetails,
                  preferredCompletedOn:
                    estimate?.properties?.preferredCompletedOn,
                  closingDate: estimate?.properties?.closingDate,
                  contacts: contacts,
                  userId: user?.id,
                  clientNotes: estimate?.properties?.clientNotes,
                  totalValue: estimate?.properties?.totalValue ?? 0,
                  approvalStep: 0,
                },
              }
              dispatch(
                estimateActions.approval(
                  payload,
                  (success: boolean, status: string, payAtClose) => {
                    dispatch(
                      configActions.setConfigValue({
                        type: 'overlayLoading',
                        value: false,
                      })
                    )
                    if (success) {
                      dispatch(
                        configActions.setConfigValue({
                          type: 'overlayLoading',
                          value: false,
                        })
                      )
                      success && push(`success`)
                    }
                  }
                )
              )
            } else {
              dispatch(
                configActions.setConfigValue({
                  type: 'overlayLoading',
                  value: false,
                })
              )
            }
          }
        )
      )
    }
  }

  const buttonText = () => {
    if (paymentOption === 'pay_at_close') {
      if (agentAsHomeownerRepresentative === 'myself') {
        return 'Sign Service Agreement'
      }
      return 'Request Pay-at-Close'
    } else {
      if (isHomewardCXSupport) {
        return 'Approve Estimate'
      }
      if (propertyToBeSold) {
        return 'Sign Service Agreement'
      }
      return 'Terms of Service'
    }
  }

  return (
    <Grid className={classes.container} spacing={2}>
      {agentIsHomeowner && (
        <Grid item xs={12} lg={4}>
          <Typography
            variant="body2"
            className={classes.question}
            style={{ marginTop: '24px' }}
          >
            What is your role in this transaction?
          </Typography>
          <Autocomplete
            label=""
            error={showWarnings && isEmpty(role)}
            value={
              contactInEstimate?.role === CONTACT_ROLE.AGENT_HOMEOWNER
                ? getContactRoleOpt().find(
                    (o) => o.key === CONTACT_ROLE.HOMEOWNER
                  )
                : getContactRoleOpt().find((o) => o.key === role)
            }
            options={getContactRoleOpt()}
            onChange={(value: any) => {
              saveNewValue('role', value.key)
            }}
          />
        </Grid>
      )}
      {showPayAtCompletionFlow && (
        <Grid item>
          <Scheduling isHomeowner={isHomeowner} />
        </Grid>
      )}

      {showPayAtCompletionFlow &&
        (agentContact ||
          estimate?.properties?.propertyToBeSold ||
          agentIsHomeowner) && (
          <Grid
            style={{
              background: '#EAF2FE',
              borderRadius: '8px',
              padding: '1rem',
              border: '1px solid #ACCAFC',
            }}
          >
            <PropertyStatus isHomeowner={isHomeowner} />
          </Grid>
        )}

      {/* {!showPayAtCompletionFlow && agentContact && <PayAtClose />} */}
      {propertyToBeSold && showPayAtClose && <PayAtClose />}

      {propertyToBeSold &&
        !agentContact &&
        paymentOption === 'pay_at_close' &&
        (!agentIsHomeowner ||
          agentAsHomeownerRepresentative === 'listing_agent') && (
          <ListingAgentInformation />
        )}

      {agentAsHomeownerRepresentative === 'myself' &&
        paymentOption === 'pay_at_close' && (
          <ListingAgreement
            setListingAgreementDocument={setListingAgreementDocument}
            listingAgreementDocument={listingAgreementDocument}
          />
        )}

      {/* {(paymentOption === 'pay_at_completion' || showPayAtCompletionFlow) && ( */}
      {showPayAtCompletionFlow && (
        <>
          <SmsConsent />

          {(paymentOption !== 'pay_at_close' || agentIsHomeowner) && (
            <SchedulerContact
              // value={scheduler || 'other'}
              showWarnings={showWarnings}
            />
          )}
          <AccessDetails />
        </>
      )}
      {/* </>
      )} */}

      <BottomButtons
        buttonBack={{
          title: 'Back to Estimate',
          action: () => {
            goBack()
          },
          // disable: true,
        }}
        buttonNext={{
          title: buttonText(),
          action: submitStep,
          disable: !validate(),
        }}
      />
    </Grid>
  )
}

export default Step1Homeowner
