import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Estimate } from 'ducks/types'
import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import { houseEmpty, marketingContentDefault, motionPhotosOn } from 'assets'
import { ESTIMATE_PUBLIC_STATUS, formatTimestamp } from 'helpers/index'
import {
  GetApp,
  Help,
  ContactMail,
  Share,
  ArrowLeftRounded,
  Info,
  ArrowBack,
} from '@material-ui/icons'
import ContactUsModal from '../ContactUsModal'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import AddContactModal from 'components/UI/CustomUI/atoms/AddContactModal'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import DiscountBanner from '../DiscountBanner'
import { icons } from 'assets'
import EstimateDetailModal from '../EstimateDetailModal'
import Badge from './Badge'
import MarketingContent from '../MarketingContent'

type Props = {
  estimate: Estimate | null
  onDownloadPDF: (onlyApproved?: boolean) => void
  onShare: () => void
  serviceable?: boolean
  ptp?: boolean
  shared?: boolean
}

export const STATUS_MAP = {
  'In Progress': {
    text: 'Composing...',
    color: 'var(--bosscat-black-200)',
    textColor: 'var(--bosscat-black-700)',
  },
  'Ready_for_release': {
    text: 'Composing...',
    color: 'var(--bosscat-black-200)',
    textColor: 'var(--bosscat-black-700)',
  },
  Ocr_processing: {
    text: 'Composing...',
    color: 'var(--bosscat-black-200)',
    textColor: 'var(--bosscat-black-700)',
  },
  Completed: {
    text: 'Completed',
    color: 'var(--bosscat-green-100)',
    textColor: 'var(--bosscat-green-700)',
  },
  Approved: {
    text: 'Approved',
    color: 'var(--bosscat-blue-700)',
    textColor: 'var(--white-color)',
  },
  Expired: {
    text: 'Expired',
    color: 'var(--renovation-red-700)',
    textColor: 'var(--white-color)',
  },
}



const EstimateLeft = ({ estimate, onDownloadPDF, onShare, serviceable, ptp, shared }: Props) => {
  const [isImageError, setIsImageError] = React.useState(false)
  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const classes = useStyles()
  const propertyImage = estimate?.properties.propertyImageUrl
  const [openContactUsModal, setOpenContactUsModal] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const [openEstimateInfo, setOpenEstimateInfo] = useState(false)
  const [videoOpened, setVideoOpened] = useState(false)
  const history = useHistory()
  const isPillarToPost = estimate?.properties.affiliate === 'pillartopost' || estimate?.properties.affiliate === 'pillar to post'
  const estimateNotApproved = !shared && estimate?.publicStatus !== ESTIMATE_PUBLIC_STATUS.APPROVED

  const newRef = React.useRef<any>(null)

  const handleGoBack = () => {
    history.push('/p/estimates')
  }

  const handleHelp = () => {
    setOpenContactUsModal(true)
  }

  const handleVideoPlay = () => {
    setOpenVideoModal(true)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setShowDownloadDialog(false);
    }
  };

  const handleClickDownload = () => {
    setShowDownloadDialog(true);
  }

  return (
    <div className={classes.container}>
      <ContactUsModal
        open={openContactUsModal}
        setOpen={setOpenContactUsModal}
      />

      {!isMobile ? (
        <>
          {/*    {!isMobile && (
            <Box className={classes.imgContainer}>
              {propertyImage && !isImageError ? (
                <img
                  className={classes.propertyImg}
                  onError={() => setIsImageError(true)}
                  src={estimate?.properties.propertyImageUrl || ''}
                  alt="propertyImage"
                />
              ) : (
                <img
                  className={classes.propertyImg}
                  src={houseEmpty}
                  alt="propertyImage"
                />
              )}
              <Badge
                status={estimate?.publicStatus as ESTIMATE_PUBLIC_STATUS}
              />
            </Box>
          )} */}
          <Box>
            <Typography className={classes.address}>
              {estimate?.properties?.address?.line_1} {estimate?.properties?.address?.line_2}
            </Typography>
            <Typography className={classes.address}>
              {estimate?.properties?.address?.city}{estimate?.properties?.address?.state ? `, ${estimate?.properties?.address?.state}` : ''} {estimate?.properties?.address?.zipCode}
            </Typography>
          </Box>
          <Typography
            className={classes.title}
          >{`Estimate #${estimate?.properties.publicId}`}</Typography>
          <Box>
            <Box className={classes.infoRow} style={{ marginBottom: '.3rem' }}>
              <Typography className={classes.infoLabel}>Requested by:</Typography>
              <Typography className={classes.infoValue}>
                {estimate?.properties?.requester?.fullName}
              </Typography>
            </Box>
            <Box className={classes.infoRow}>
              <Typography className={classes.infoLabel}>
                Estimate created on:
              </Typography>
              <Typography className={classes.infoValue}>
                {formatTimestamp(estimate?.createdOn, 'MM/DD/YYYY')}
              </Typography>
            </Box>
            {estimate?.lastDeliveredOn && <Box className={classes.infoRow}>
              <Typography className={classes.infoLabel}>
                Estimate updated on:
              </Typography>
              <Typography className={classes.infoValue}>
                {formatTimestamp(estimate?.lastDeliveredOn, 'MM/DD/YYYY')}
              </Typography>
            </Box>}
            {estimate?.approvedOn && !ptp && <Box className={classes.infoRow}>
              <Typography className={classes.infoLabel}>
                Estimate approved on:
              </Typography>
              <Typography className={classes.infoValue}>
                {formatTimestamp(estimate?.approvedOn, 'MM/DD/YYYY')}
              </Typography>
            </Box>}
          </Box>
        </>
      ) : (
        <Box className={classes.headerMobile}>
          <Box className={classes.topInfoMobile}>
            <Box onClick={handleGoBack}>
              <ArrowBack style={{ color: 'var(--bosscat-black-600)' }} />
            </Box>
            <Box className={classes.addressMobile}>
              <Typography className={classes.addressTitle}>
                {estimate?.properties?.address?.line_1}
              </Typography>
              <Typography
                className={classes.addressSubtitle}
              >{`${estimate?.properties?.address?.city}, ${estimate?.properties?.address?.state}`}</Typography>
            </Box>
            <Box className={classes.infoButton} onClick={() => setOpenEstimateInfo(true)}>
              <Info style={{ color: 'var(--bosscat-blue-600)' }} />
            </Box>
          </Box>
        </Box>
      )}

      <>
        <Box className={classes.buttonsContainer}>
          <Box className={`${classes.flexWrap} ${classes.buttonRow}`}>
            <Box className={classes.howCanWeHelp}>
              <Button
                endIcon={<Help />}
                title="Get Help"
                className={classes.goldButton}
                style={{}}
                onClick={handleHelp}
              >
                <Typography className={classes.goldButtonText}>
                  {isMobile ? 'Help' : 'Get Help'}
                </Typography>
              </Button>
            </Box>
            <Box hidden={isMobile}>
              <Button
                endIcon={<img src={motionPhotosOn} alt="motion photos on" />}
                title="Tutorial"
                className={classes.goldButton}
                onClick={handleVideoPlay}
              >
                <Typography className={classes.goldButtonText}>
                  Tutorial
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box className={classes.buttonRow}>
            <Button
              title="Download"
              className={classes.actionButton}
              onClick={handleClickDownload}
            >
              <icons.GetApp fontSize='small' style={{ color: 'var(--bosscat-grey-1000)' }} />
              <Typography className={classes.actionButtonText}>
                Download
              </Typography>
              {showDownloadDialog &&
                <div ref={newRef} className={classes.dialogButtons}>
                  <div
                    style={{ minWidth: "110px" }}
                    onClick={() => onDownloadPDF(false)} >
                    <Typography className={classes.dialogText}>
                      Download all estimated items
                    </Typography>
                  </div>
                  <div style={{ width: 2, height: 60, backgroundColor: "white" }}>

                  </div>
                  <div
                    style={{ minWidth: "110px" }}
                    onClick={() => onDownloadPDF(true)} >
                    <Typography className={classes.dialogText}>
                      {`Download only items added to the ${estimateNotApproved ? "shopping cart" : "project"}`}
                    </Typography>
                  </div>
                </div>}
            </Button>
            <Button
              title="Contacts"
              className={classes.actionButton}
              onClick={() => setOpenModal(true)}
            >
              <icons.ContactMail fontSize='small' style={{ color: 'var(--bosscat-grey-1000)' }} />
              <Typography className={classes.actionButtonText}>
                Contacts
              </Typography>
            </Button>
            <Button
              title="Share estimate"
              className={classes.actionButton}
              onClick={onShare}
            >
              <icons.Share fontSize='small' style={{ color: 'var(--bosscat-grey-1000)' }} />
              <Typography className={classes.actionButtonText}>
                Share
              </Typography>
            </Button>
          </Box>
        </Box>
        {estimateNotApproved && <Box className={classes.discountBannerContainer}>
          <DiscountBanner />
        </Box>}
        <Box className={classes.marketContent} hidden={isMobile}>
          <MarketingContent />
        </Box>
      </>
      <Modal
        open={openModal}
        setOpen={() => setOpenModal(false)}
        size="sm"
        className={classes.modal}
        dialogContentSx={{
          display: 'block !important'
        }}
        title={
          <Box style={{ marginRight: '1rem' }}>
            <Typography variant="h5" style={{ paddingLeft: 0 }}>
              Estimate Contacts
            </Typography>
            <Typography style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}>
              All listed contacts have access to this estimate in their BOSSCAT portal
            </Typography>
          </Box>
        }
      >
        <AddContactModal setOpenModal={setOpenModal} />
      </Modal>

      <Modal
        open={openEstimateInfo}
        setOpen={() => setOpenEstimateInfo(false)}
        size="xl"
        className={classes.estimateInfoModal}
      /* title={
        <Grid>
          <Typography className={classes.estimateInfoTitle}>
            {estimate?.properties.address?.line_1 || ''}
          </Typography>
          <Typography className={classes.estimateInfoSubtitle}>
            {estimate?.properties.address?.city || ''}
          </Typography>
        </Grid>
      } */
      >
        <EstimateDetailModal ptp={isPillarToPost} />
      </Modal>

      <Modal
        open={openVideoModal}
        setOpen={setOpenVideoModal}
        onClose={() => setVideoOpened(true)}
        size="lg"
        title={
          <Typography variant="h5" style={{ paddingLeft: 0 }} className={classes.text}>
            Tutorial
          </Typography>
        }
      >
        <iframe
          data-testid="punchlist-video"
          title="BOSSCAT"
          className={classes.video}
          src={`https://www.youtube.com/embed/5237HyFN44M${videoOpened ? '' : '?autoplay=1'}`}
          allow={videoOpened ? '' : 'autoplay'}
        />
      </Modal>
    </div>
  )
}

export default EstimateLeft