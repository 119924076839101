
import { FC, useEffect, useState, useCallback } from 'react'
import { DesktopPage, PrivateLoader } from '../../../../templates'
import useStyles from './styles'
import { Box, Button, Typography, InvoiceHeader, Grid, FormControlLabel, RadioGroup, Radio, STextField, PaymentModal, DownloadButton } from '../../../../UI'
import { history, getTextGuarantee, getTextUrl, formatTimestamp, round, RESOURCE_TYPE } from '../../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getJob, getInvoice } from '../../../../../ducks/selectors'
import { InfoProps, SummaryProps, PaymentProps } from './types'
import { jobActions, invoiceActions, configActions, paymentsActions } from '../../../../../ducks/actions'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { generatePdf } from './ItemsPdf'
import html2canvas from 'html2canvas'
import { useIsXsScreen } from '../../../../../hooks'

const Info: FC<InfoProps> = ({ dueDate, createdOn, amountDue, name, line1, line2, phone, onClick }) => {
  const classes = useStyles()
  const formatDate = 'M/D/YYYY'

  const [xsScreen] = useIsXsScreen()

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid container item lg={3} xs={12}>
        <Box className={classes.column}>
          <Typography variant='caption' className={classes.detail}>Invoice Prepared For:</Typography>
          <Typography variant='body2' className={classes.body2}>{name}</Typography>
          <Typography variant='caption' className={classes.detail}>{line1}</Typography>
          <Typography variant='caption' className={classes.detail}>{line2}</Typography>
          <Typography variant='caption' className={classes.detail}>{phone}</Typography>
        </Box>
      </Grid>
      <Grid lg={4} className={classes.date}>
        <Grid container item lg={2} xs={12}>
          <Box className={classes.column}>
            <Typography variant='caption' className={classes.detail}>Issue Date:</Typography>
            <Typography variant='body2' className={classes.body2}>{formatTimestamp(createdOn, formatDate)}</Typography>
          </Box>
        </Grid>
        <Grid container item lg={5} xs={12}>
          <Box className={classes.column}>
            <Typography variant='caption' className={classes.detail}>Due Date:</Typography>
            <Typography variant='body2' className={classes.body2}>{"Due Upon Receipt"}</Typography>
            {/* <Typography variant='body2' className={classes.body2}>{formatTimestamp(dueDate, formatDate)}</Typography> */}
          </Box>
        </Grid>
      </Grid>
      <Grid container item lg={5} xs={12} className={classes.right}>
        <Box className={classes.white}>
          <Typography variant='h5' className={classes.h5Title}>Payment Due:</Typography>
          <Box className={classes.box}>
            <Typography variant='h5' className={classes.h5Value}>${round(amountDue, 2)}</Typography>
            {xsScreen && (
              <DownloadButton
                onClick={onClick}
                className={classes.download}
              />)}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const Summary: FC<SummaryProps> = ({ subtotal, completed, pending, discounts, isNC, tax, total, taxRate }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant='h6' className={classes.h6}>Summary Information</Typography>
      </Grid>
      <Grid container item xs={6}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2}>Invoice Sub Total:</Typography>
          <Typography variant='body2' className={classes.body2}>Sub Total (Completed):</Typography>
          <Typography variant='body2' className={classes.body2}>Sub Total (Pending):</Typography>
        </Box>
      </Grid>
      <Grid container item xs={6} className={classes.right}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2R}>${round(subtotal, 2)}</Typography>
          <Typography variant='body2' className={classes.body2R}>${round(completed, 2)}</Typography>
          <Typography variant='body2' className={classes.body2R}>${round(pending, 2)}</Typography>
        </Box>
      </Grid>

      <Grid container item xs={12}><Box className={classes.line} /></Grid>

      <Grid container item xs={6}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2}>Discounts:</Typography>
          {isNC && <Typography variant='body2' className={classes.body2}>Taxes({round(taxRate, 2)}%)</Typography>}
        </Box>
      </Grid>

      <Grid container item xs={6} className={classes.right}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2R}>$({round(discounts, 2)})</Typography>
          {isNC && <Typography variant='body2' className={classes.body2R}>${round(tax, 2)}</Typography>}
        </Box>
      </Grid>

      <Grid container item xs={12}><Box className={classes.line} /></Grid>

      <Grid container item xs={6}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2}>Total:</Typography>
        </Box>
      </Grid>

      <Grid container item xs={6} className={classes.right}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2R}>${round(total, 2)}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

const Payment: FC<PaymentProps> = ({
  paymentReceived, currentBalance, outstandingBalance, setPrice, setOptionSelected,
  optionSelected, options
}) => {
  const classes = useStyles()
  const [other, setOther] = useState('')
  // setPrice(currentBalance)

  const handleChange = (event: any) => {
    setOptionSelected(event.target.value)
    switch (event.target.value) {
      case options[0]:
        setPrice(currentBalance)
        break
      case options[1]:
        setPrice(outstandingBalance)
        break
      default:
        setPrice(Number(other))
        break
    }
  }

  const handleOther = (event: any) => {
    setOther(event.target.value)
    setPrice(Number(event.target.value))
  }

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant='subtitle1' className={classes.h6}>Payment:</Typography>
      </Grid>
      <Grid container item xs={8}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2}>Total Payment Received:</Typography>
        </Box>
      </Grid>
      <Grid container item xs={4} className={classes.right}>
        <Box className={classes.column}>
          <Typography variant='body2' className={classes.body2R}>${round(paymentReceived, 2)}</Typography>
        </Box>
      </Grid>

      <Grid container item xs={12}><Box className={classes.line} /></Grid>

      {outstandingBalance > 0 &&
        <>
          <Grid container item xs={8}>
            <Box className={classes.column}>
              <Typography variant='body2' className={classes.body2}>Current Balance Due:</Typography>
            </Box>
          </Grid>
          <Grid container item xs={4} className={classes.right}>
            <Box className={classes.column}>
              <Typography variant='body2' className={classes.body2R}>${round(currentBalance, 2)}</Typography>
            </Box>
          </Grid>
          <Grid container item xs={12}><Box className={classes.line} /></Grid>
          <Grid container item xs={6}>
            <RadioGroup value={optionSelected} onChange={handleChange}>
              {options.map((option, index) =>
                <FormControlLabel
                  key={index} value={option} control={<Radio color='primary' />}
                  label={<Typography variant='caption'>{option}</Typography>} className={classes.radio}
                />
              )}
            </RadioGroup>
          </Grid>
          <Grid container item xs={6} className={classes.right}>
            <Box className={classes.column}>
              <Typography variant='body2' className={classes.radioValue}>${round(currentBalance, 2)}</Typography>
              <Typography variant='body2' className={classes.radioValue}>${round(outstandingBalance, 2)}</Typography>
              <STextField
                type='number'
                variant='outlined'
                size='small'
                value={other}
                onChange={(event) => handleOther(event)}
                className={classes.field}
                placeholder={'$' + round(outstandingBalance, 2)}
              />
            </Box>
          </Grid>
        </>}
    </Grid>
  )
}

const Invoice: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: jobId, subId: invoiceId } = history.getParamValues()
  const job = useSelector(getJob())
  const invoice = useSelector(getInvoice())
  const push = history.usePush()
  const goBack = history.useGoBack()
  const [downloadingPdf, setDownloadingPdf] = useState(false)

  const { properties } = job ?? {}
  const { address, contacts } = properties ?? {}
  const { line_1: street, line_2, city, state, zipCode } = address ?? {}
  const { firstName = '', lastName = '', email = '', phone = '' } = contacts && contacts.length > 0 ? contacts[0] : {}
  const [pageLoading, setPageLoading] = useState(true)
  const { preparedFor } = invoice ?? {}
  const [clientSecret, setClientSecret] = useState('')
  // info
  const name = preparedFor && preparedFor.fullName ? preparedFor.fullName : ''
  const phoneAdd = preparedFor && preparedFor.phone ? preparedFor.phone : ''
  const add = invoice && invoice.address ? invoice.address.city + ', ' + invoice.address.line_1 : ''
  const line1 = invoice?.address ? `${invoice.address.line_1} ${invoice.address.line_2}` : ''
  const line2 = invoice?.address ? `${invoice.address.city} ${invoice.address.state} ${invoice.address.zipCode}` : ''
  const isNC = invoice && invoice?.address?.state ? invoice?.address?.state === 'NC' : false
  const dueDate = invoice ? invoice.dueDate : 0
  const createdOn = invoice ? invoice.createdOn : 0
  const amountDue = invoice ? invoice.amountDue : 0

  // summary
  const subtotal = invoice ? invoice.subtotal : 0
  const completed = invoice ? invoice.subtotalCompleted : 0
  const pending = invoice ? invoice.subtotalPending : 0
  const discounts = invoice ? invoice.discount : 0
  const taxRate = invoice ? invoice.taxRate : 0
  const taxNum = invoice ? invoice.tax : 0
  const tax = isNC ? taxNum : 0
  const total = invoice ? invoice.total : 0

  // Payment
  const paymentReceived = invoice ? invoice.amountPaid : 0
  const currentBalance = invoice ? invoice.amountDue : 0
  const outstandingBalance = invoice ? invoice.outstandingBalance : 0
  const [price, setPrice] = useState(-1)
  const options = ['Balance Due Now:', 'Invoice Pay Off:', 'Other Amount:']
  const [optionSelected, setOptionSelected] = useState<string | null>(options[0])

  const [xsScreen] = useIsXsScreen()

  const fetchInvoiceView = useCallback((loadings = true) => {
    if (loadings) setPageLoading(true)
    dispatch(invoiceActions.fetchInvoice(invoiceId ?? '',
      (_succ: boolean) => {
        setPageLoading(false)
        if (!_succ) {
          goBack()
        }
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invoiceId])

  const fetchJobView = useCallback((loadings = true) => {
    if (loadings) setPageLoading(true)
    dispatch(jobActions.fetchJob(jobId,
      (_succ: boolean) => {
        setPageLoading(false)
        if (!_succ) {
          goBack()
        } else {
          fetchInvoiceView(loadings)
        }
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jobId])

  useEffect(() => { fetchJobView() }, [fetchJobView])

  useEffect(() => {
    if (window.location.href.includes('payment_intent') &&
      window.location.href.includes('payment_intent_client_secret')) {
      toast.success('Payment Accepted!')
      push('p/jobs')
    }
  })

  const handlePdf = async () => {
    if (!downloadingPdf) {
      setTimeout(async () => {
        setDownloadingPdf(true)
        const userInformationNode = document.getElementById(
          'user-and-item-information'
        )
        const userInfoGrid = (userInformationNode
          ?.childNodes[0] as any) as HTMLElement
        const punchlistLogo = userInfoGrid.children[0]?.children[0]
          ?.children[0] as HTMLElement
        const mailIcon = userInfoGrid.children[1]?.children[0] as HTMLElement

        const [
          punchlistLogoCanvas,
          mailIconCanvas
        ] = await Promise.all([
          html2canvas(punchlistLogo!),
          html2canvas(mailIcon!)
        ])

        const punchlistLogoUri = punchlistLogoCanvas.toDataURL('image/png', 1.0)
        const mailIconUri = mailIconCanvas.toDataURL('image/png', 1.0)

        await generatePdf({
          groupedItems: invoice,
          userInfo: {
            name: `${firstName} ${lastName}`,
            city: city ? `${city},` : '',
            address: street ?? '',
            sent: phone ?? '',
            approvedOn: '',
            mail: email ?? '',
            total: `Invoice # ${invoiceId}`
          },
          info: {
            name: name,
            phone: add + ' ' + phoneAdd,
            dueDate: dueDate,
            createdOn: createdOn,
            amountDue: amountDue
          },
          summary: {
            isNC: isNC,
            subtotal: subtotal,
            completed: completed,
            taxRate: taxRate,
            pending: pending,
            discounts: discounts,
            total: total,
            tax: tax,
            paymentReceived: paymentReceived,
            currentBalance: currentBalance,
            outstandingBalance: outstandingBalance,
            other: '0',
            price: price,
            optionSelected: optionSelected
          },
          imageUris: {
            punchlistLogoUri,
            mailIconUri
          }
        })
        setDownloadingPdf(false)
      }, 100)
    }
  }

  const handleClose = () => {
    stopOverlay()
    setClientSecret('')
  }

  const stopOverlay = () => {
    dispatch(configActions.setConfigValue({ type: 'overlayLoading', value: false }))
  }

  const handleSuccess = () => {
    stopOverlay()
    toast.success('Payment Accepted!')
    setClientSecret('')
    push('p/jobs')
  }

  const handlePayment = () => {
    if (price === -1) { setPrice(currentBalance) }
    dispatch(configActions.setConfigValue({ type: 'overlayLoading', value: true }))
    dispatch(paymentsActions.fetchStripeSecret({
      amount: price === -1 ? currentBalance : price, resourceId: jobId, resourceType: RESOURCE_TYPE.JOB
    }, (secret?: string) => {
      if (secret && !isEmpty(secret)) {
        setClientSecret(secret)
      } else stopOverlay()
    }))
  }

  return (
    <DesktopPage title='Invoice'>
      <PrivateLoader loading={pageLoading}>
        <Box className={classes.root}>
          {!xsScreen && (
            <InvoiceHeader
              title={firstName ? firstName.concat(' ', lastName) : ''}
              addressLine1={(street ? `${street} ` : '') + (line_2 ? `#${line_2}` : '')}
              addressLine2={`${city}, ${state} ${zipCode}`}
              phone={phone ?? ''}
              mail={email ?? ''}
              invoice={'Invoice #' + invoiceId}
              onClick={handlePdf}
            />)}

          {xsScreen && <Typography className={classes.title}>Invoice #{invoiceId}</Typography>}

          <Info onClick={handlePdf} name={name} line1={line1} line2={line2} phone={phoneAdd} dueDate={dueDate} createdOn={createdOn} amountDue={amountDue} />

          <Summary
            isNC={isNC} subtotal={subtotal} completed={completed} taxRate={taxRate}
            pending={pending} discounts={discounts} total={total} tax={tax}
          />

          <PaymentModal
            open={!isEmpty(clientSecret) && price > 0 && !isEmpty(jobId)}
            setOpen={handleClose}
            resourceId={jobId}
            clientSecret={clientSecret}
            price={price}
            onSuccess={handleSuccess}
            returnUrl={`${window.location.href}`}
          />
          <Payment
            paymentReceived={paymentReceived} currentBalance={currentBalance}
            outstandingBalance={outstandingBalance} setPrice={setPrice}
            setOptionSelected={setOptionSelected}
            optionSelected={optionSelected}
            options={options}
          />

          <Grid container spacing={1}>
            {outstandingBalance > 0 &&
              <Grid container item xs={12} className={`${classes.footer} ${classes.right}`}>
                <Button
                  variant='contained'
                  type='submit'
                  className={classes.button}
                  disabled={(price === -1 ? currentBalance : price) === 0}
                  onClick={handlePayment}
                >
                  Pay ${price === -1 ? round(currentBalance, 2) : round(price, 2)}
                </Button>
              </Grid>}

            <Grid container item xs={12} className={classes.right}>
              <Box className={classes.column}>
                <Typography variant='body2' className={classes.body2R}>Invoice total: ${round(total, 2)}</Typography>
                <Typography variant='body2' className={classes.body2R}>Total paid: ${round(paymentReceived, 2)}</Typography>
                <Typography variant='body2' className={classes.body2Red}>Outstanding Balance: ${round(outstandingBalance, 2)}</Typography>
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Box className={classes.column}>
                <Typography variant='caption' className={classes.smDetail}>{getTextGuarantee()}</Typography>
                <Box className={classes.endLine} />
                <Typography variant='caption' className={classes.smDetail}>{getTextUrl()}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PrivateLoader>
    </DesktopPage>
  )
}

export default Invoice
