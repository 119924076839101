import { useState, useEffect, useRef } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import useStyles from '../styles'
import { PrivateLoader } from 'components/templates'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {
  greenLeap,
  homeBaseWhite,
  loanDepot,
  theHomeDepot,
} from 'assets/index'
import { Tabs, Tab } from 'components/UI'
import { TabPanel } from '../../OrderMaintenance/Dashboard'
import PropertyActivity from './Tabs/PropertyActivity'
import PropertyInformation from './Tabs/PropertyInformation'
import SystemInsights from './Tabs/SystemInsights'
import ConditionCostByRoom from './Tabs/ConditionCostByRoom'
import RenovationEstimate from './Tabs/RenovationEstimate'
import PropertyConditions from './Tabs/PropertyConditions'
import GreenReport from './Tabs/GreenReport'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  estimatesActions,
  newEstimateActions,
  newOrderMaintenanceActions,
  propertyContactActions,
  propertyDetailActions,
  territoriesActions,
} from 'ducks/actions'
import {
  getNewEstimateValue,
  getPropertyDetail,
  getTerritory,
  getUser,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { ESTIMATE_STATUS, FORM_TYPE, history } from 'helpers/index'
import { NewEstimateType } from 'ducks/newEstimate/types'
import PropertyDisclaimer from 'components/UI/CustomUI/atoms/PropertyDisclaimer'
import {
  useIsMdScreen,
  useIsSmScreen,
  useIsXsScreen,
} from 'hooks/useMediaQuery'

const PropertyView = () => {
  const dispatch = useDispatch()
  const styles = useStyles()

  const { id: propertyId } = history.getParamValues()
  const user = useSelector(getUser)
  const userMail = useSelector(getUserPrimaryEmail)
  const territory = useSelector(getTerritory())
  const isNar = useSelector(getNewEstimateValue('isNar'))
  const affiliate = useSelector(getNewEstimateValue('affiliate'))

  const { push } = useHistory()

  const property = useSelector(getPropertyDetail())
  const viewRef = useRef<HTMLDivElement>(null)

  const [currentTab, setCurrentTab] = useState<number>(0)
  const [loading, setLoading] = useState(true)

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setCurrentTab(newValue)
  }

  const updateNewEstimateValues = (data: Partial<NewEstimateType>) => {
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: {
          ...data,
        },
      })
    )
  }

  const handleInspection = () => {
    updateNewEstimateValues({
      formType: FORM_TYPE.CLIENT_INSPECTION_REPORT,
      address: property.address,
      step: 0,
      estimate: { createdBy: user.id, status: ESTIMATE_STATUS.NEW },
      serviceable: territory?.serviceable,
      mainContact: {
        email: userMail,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: String(user.phone),
        sendEstimate: true,
      },
    })
    dispatch(
      newEstimateActions.createEstimateV2((succ) => {
        push('/estimate?p=2a')
      })
    )
  }

  const handleRenovation = () => {
    updateNewEstimateValues({
      formType: FORM_TYPE.CLIENT_REPAIR_LIST,
      address: property.address,
      step: 0,
      estimate: { createdBy: user.id, status: ESTIMATE_STATUS.NEW },
      mainContact: {
        email: userMail,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: String(user.phone),
        sendEstimate: true,
      },
    })

    dispatch(
      newEstimateActions.createEstimateV2((succ) => {
        dispatch(
          estimatesActions.fetchEstimatesServices(
            {
              formType: FORM_TYPE.CLIENT_REPAIR_LIST,
              serviceable: territory?.serviceable || false,
              isNar,
              affiliate,
            },
            () => { }
          )
        )
        dispatch(
          newEstimateActions.updateEstimate(
            { formType: FORM_TYPE.CLIENT_REPAIR_LIST },
            (success, estimate) => {
              if (success && estimate?.id) {
                dispatch(
                  newEstimateActions.setNewEstimateValue({
                    attr: 'formType',
                    value: FORM_TYPE.CLIENT_REPAIR_LIST,
                  })
                )
              }
            }
          )
        )

        push('/estimate?p=2b')
      })
    )
  }

  const handleGetAnEstimate = () => {
    updateNewEstimateValues({
      address: property.address,
      estimate: { createdBy: user.id, status: ESTIMATE_STATUS.NEW },
      serviceable: territory?.serviceable,
      mainContact: {
        email: userMail,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: String(user.phone),
        sendEstimate: true,
      },
      ...(territory?.serviceable ? {} : { formType: FORM_TYPE.CLIENT_INSPECTION_REPORT })
    })
    push('/estimate?from=property')
  }

  const handleMaintenance = () => {
    dispatch(
      territoriesActions.fetchTerritory(
        property?.address?.zipCode,
        (succ, terr) => {
          dispatch(
            newOrderMaintenanceActions.setNewOrderMaintenanceValue({
              attr: 'serviceable',
              value: terr?.serviceable || false,
            })
          )
          push('/maintenance?from=property')
          dispatch(
            newOrderMaintenanceActions.setNewOrderMaintenanceValue({
              attr: 'propertyAddress',
              value: property.address,
            })
          )
        }
      )
    )
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      propertyDetailActions.fetchPropertyDetail(
        { id: propertyId },
        (succ: boolean) => {
          setLoading(false)
          if (!succ) {
            push('/p/dashboard')
          }
        }
      )
    )

    dispatch(
      propertyContactActions.fetchPropertyContacts(
        { id: propertyId },
        (succ: boolean) => { }
      )
    )

    dispatch(newEstimateActions.cleanNewEstimate())


    return () => {
      const element = document.getElementById('user-menu')
      if (!element) return
      element.style.display = 'block'
    }
  }, [])

  useEffect(() => {
    if (property?.address?.zipCode)
      dispatch(territoriesActions.fetchTerritory(property?.address?.zipCode))
  }, [property])

  const handleScroll = (e: any) => {
    const element = document.getElementById('user-menu')

    if (!element) return

    if (e.target.scrollTop > 50) {
      element.style.display = 'none'
    } else {
      element.style.display = 'block'
    }
  }

  const handleDesignBook = () => {
    window.open(
      'https://cloud.3dissue.net/36427/36380/36728/94665/index.html?71894',
      '_blank'
    )
  }

  const handleLoanDepot = () => {
    window.open(
      'https://www.loandepot.com/heloc?getstarted=1&leadsource=website_bosscat_heloc&entryPoint=',
      '_blank'
    )
  }

  const showGreenReport = property?.details?.greenReport?.categories?.length

  return (
    <PrivateLoader loading={loading}>
      <Grid
        container
        direction="column"
        style={{ overflow: 'overlay', position: 'relative' }}
        innerRef={viewRef}
        onScroll={handleScroll}
      >
        <Box
          className={styles.portfolioView}
          hidden={xsScreen}
          style={{ display: isSmallScreen ? 'none' : 'flex' }}
        >
          <img
            src={homeBaseWhite}
            alt="homebbase"
            className={styles.homeBaseLogo}
          />
          <Typography className={styles.active}>PROPERTY REPORT</Typography>
        </Box>

        <Grid
          container
          direction="column"
          style={{
            marginTop: '64px',
            padding: '0px 32px',
            gap: '16px',
            position: 'absolute',
            minHeight: 'calc(100% - 64px)',
          }}
        >
          <Grid item container>
            <Grid item style={{ padding: '8px 0' }}>
              <Typography className={styles.propertyTitle}>
                {isSmallScreen
                  ? `${property.address.line_1}, ${property.address.city} ${property.address.state}`
                  : property?.address?.fullAddress || ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs>
            <Grid item container style={{ height: 'fit-content' }}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'var(--bosscat-blue-900)',
                    zIndex: '-1',
                    height: '100%',
                    borderRadius: '8px',
                  },
                }}
                classes={{ root: styles.navigationTabsStyle }}
                value={currentTab}
                onChange={handleTabChange}
                className={styles.navigationTabs}
                TabScrollButtonProps={{ style: { height: '44px' } }}
              >
                <Tab
                  className={styles.navigationTab}
                  style={{ width: 'fit-content', margin: 0 }}

                  label="Property Information"
                />
                <Tab
                  className={styles.navigationTab}
                  style={{ width: 'fit-content', margin: 0 }}
                  label="Property Activity"
                />
                {property?.details?.greenReport?.categories?.length && (
                  <Tab
                    className={styles.navigationTab}
                    label={
                      <Box className={styles.iconLabel}>
                        <img src={greenLeap} alt="green" />
                        <Typography
                          className={styles.lgFont}
                          style={{ fontWeight: currentTab === 2 ? 700 : 400 }}
                        >
                          Green Report
                        </Typography>
                      </Box>
                    }
                  />
                )}
                <Tab className={styles.navigationTab} label="System Insights" />
                <Tab
                  className={styles.navigationTab}
                  label="Condition Cost by Room"
                />
                <Tab
                  className={styles.navigationTab}
                  label="Renovation Estimate"
                />
                {/* <Tab className={styles.Tab} label="Property Condition" /> */}
              </Tabs>
              <Grid
                item
                container
                style={{
                  gap: '16px',
                  borderRadius: '0 0 8px 8px',
                  padding: '8px',
                  display: isSmallScreen ? 'none' : 'flex',
                  boxShadow:
                    '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
                  // justifyContent: 'center'
                }}
              >
                <Grid
                  container
                  item
                  style={{
                    gap: '4px',
                    width: isSmallScreen ? '100%' : 'fit-content',
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={`${styles.lgFont} ${styles.noTransform}`}
                    style={{ fontWeight: 700, marginLeft: '16px' }}
                  >
                    Order Now
                  </Typography>
                  <ArrowForwardIcon style={{ width: '18px', height: '18px' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg>
                  <Button
                    fullWidth
                    className={styles.navigationButton}
                    onClick={handleGetAnEstimate}
                  >
                    <Typography
                      className={`${styles.lgFont} ${styles.noTransform}`}
                    >
                      Estimate
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg>
                  <Button
                    fullWidth
                    className={styles.navigationButton}
                    onClick={handleInspection}
                  >
                    <Typography
                      className={`${styles.lgFont} ${styles.noTransform}`}
                    >
                      Inspection
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg>
                  <Button
                    fullWidth
                    className={styles.navigationButton}
                    onClick={handleMaintenance}
                  >
                    <Typography
                      className={`${styles.lgFont} ${styles.noTransform}`}
                    >
                      Maintenance
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg style={{ display: territory?.serviceable ? 'flex' : 'none' }}>
                  <Button
                    fullWidth
                    className={styles.navigationButton}
                    onClick={handleRenovation}
                  >
                    <Typography
                      className={`${styles.lgFont} ${styles.noTransform}`}
                    >
                      Renovation
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg>
                  <Button
                    fullWidth
                    className={styles.navigationButton}
                    startIcon={<img src={loanDepot} alt="loan depot icon" />}
                    onClick={handleLoanDepot}
                  >
                    <Typography
                      className={`${styles.lgFont} ${styles.noTransform}`}
                    >
                      Financing
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg>
                  <Button
                    fullWidth
                    className={styles.navigationButton}
                    onClick={handleDesignBook}
                    startIcon={
                      <img
                        src={theHomeDepot}
                        alt="the home depot"
                        style={{ height: '32px' }}
                      />
                    }
                  >
                    <Typography
                      className={`${styles.lgFont} ${styles.noTransform}`}
                    >
                      Design Book
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <TabPanel value={currentTab} index={0} style={{ width: '100%' }}>
                <PropertyInformation />
              </TabPanel>
              <TabPanel value={currentTab} index={1} style={{ width: '100%' }}>
                <PropertyActivity handleChangeTab={setCurrentTab} />
              </TabPanel>
              {showGreenReport && (
                <TabPanel
                  value={currentTab}
                  index={2}
                  style={{ width: '100%' }}
                >
                  <GreenReport />
                </TabPanel>
              )}
              <TabPanel
                value={currentTab}
                index={showGreenReport ? 3 : 2}
                style={{ width: '100%' }}
              >
                <SystemInsights />
              </TabPanel>
              <TabPanel
                value={currentTab}
                index={showGreenReport ? 4 : 3}
                style={{ width: '100%' }}
              >
                <ConditionCostByRoom />
              </TabPanel>
              <TabPanel
                value={currentTab}
                index={showGreenReport ? 5 : 4}
                style={{ width: '100%' }}
              >
                <RenovationEstimate />
              </TabPanel>
              <TabPanel
                value={currentTab}
                index={showGreenReport ? 6 : 5}
                style={{ width: '100%' }}
              >
                <PropertyConditions />
              </TabPanel>
            </Grid>
          </Grid>
          <PropertyDisclaimer />
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default PropertyView
