import { Button, Grid, Typography } from '@material-ui/core'
import useStyles from './styles'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  getContactInEstimate,
  getEstimate,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { CONTACT_ROLE } from 'helpers/constants'
import { companyRoles, history } from 'helpers/index'

const ApprovalInProgressBanner: FC = () => {
  const push = history.usePush()

  const styles = useStyles()
  const userEmail = useSelector(getUserPrimaryEmail)
  const userInEstimate = useSelector(getContactInEstimate(userEmail))
  const estimate = useSelector(getEstimate())
  const flowInProggressByUser = userInEstimate?.approvalFlowInProgress

  const agentContact = estimate?.properties?.contacts?.find((c) =>
    companyRoles.includes(c.role as CONTACT_ROLE)
  )
  const flowInProgressByAgent = agentContact?.approvalFlowInProgress
  const title = flowInProggressByUser
    ? 'It looks like you’ve already started the project ordering process'
    : 'Your Agent has already started the project ordering process'
  const subtitle = flowInProggressByUser
    ? 'To continue where you left off, click on the blue button.'
    : 'Please review the items selected by your Agent and click on the green button to complete the order.'

  if (!flowInProggressByUser && !flowInProgressByAgent) return <></>

  return (
    <Grid className={styles.container}>
      <Grid>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.subtitle}>{subtitle}</Typography>
      </Grid>
      {flowInProggressByUser && (
        <Button
          className={styles.button}
          onClick={() => push(`p/estimates/${estimate?.id}/approve`)}
        >
          Complete My Order
        </Button>
      )}
    </Grid>
  )
}

export default ApprovalInProgressBanner
