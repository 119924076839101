import { FC, useState } from 'react'
import { Dialog, Button, DialogContent, Typography, Box, Grid } from '../../..'
import useStyles from './styles'
import { CheckoutFormProps, PaymentModalProps } from './types'
import { blueSmallLogo, reportProblem } from '../../../../../assets'
import { round } from '../../../../../helpers'
import env from '@beam-australia/react-env'

import { loadStripe } from '@stripe/stripe-js'
import {
  useStripe,
  useElements,
  Elements,
  PaymentElement,
} from '@stripe/react-stripe-js'
import { CircularProgress } from '@material-ui/core'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)

const stripeOptions = {
  clientSecret: '',
  appearance: {},
}

const CheckoutForm: FC<CheckoutFormProps> = ({
  price,
  onSuccess,
  loading,
  setLoading,
  returnUrl,
}) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (!stripe || !elements || loading) return

    setErrorMessage(undefined)
    setLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl,
      },
    })

    if (error) setErrorMessage(error.message)
    else if (onSuccess) {
      onSuccess()
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      {stripe && elements && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaymentElement />
          </Grid>
          {errorMessage && (
            <Grid item xs={12} className={classes.errorMessage}>
              <img src={reportProblem} alt="" />
              <Typography variant="caption" style={{ paddingTop: '4px' }}>
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              data-testid="payment-modal-button"
              className={classes.submit}
              fullWidth
              disabled={price === 0}
            >
              {loading ? (
                <CircularProgress className={classes.spinner} size={24} />
              ) : (
                `Pay $${round(price, 2)}`
              )}
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  )
}

const PaymentModal: FC<PaymentModalProps> = ({
  open,
  setOpen = null,
  clientSecret,
  price,
  resourceId,
  onSuccess,
  returnUrl,
  handleCloseModal,
  address,
}) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    if (setOpen && !loading) setOpen(false)
    handleCloseModal && handleCloseModal()
  }

  return (
    <Dialog
      aria-label="payment-modal"
      disableEnforceFocus
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.root }}
    >
      <DialogContent className={classes.header}>
        <Box className={classes.logoContainer}>
          <img src={blueSmallLogo} alt="" className={classes.logo} />
        </Box>
        <Box className={classes.headerText}>
          <Typography>BOSSCAT Estimate</Typography>
          <Typography variant="caption" className={classes.headerTX}>
            {address || 'TX-289 Dallas'}
          </Typography>
        </Box>
      </DialogContent>
      <DialogContent>
        <Elements
          stripe={stripePromise}
          options={{ ...stripeOptions, clientSecret }}
        >
          <CheckoutForm
            price={price}
            resourceId={resourceId}
            onSuccess={onSuccess}
            setLoading={setLoading}
            loading={loading}
            returnUrl={returnUrl}
          />
        </Elements>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default PaymentModal
