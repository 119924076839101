import { Button, Grid, Typography } from '@material-ui/core'
import { bank } from 'assets/index'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import { FC } from 'react'
import useStyles from './../../../styles'

interface UsBankDisclaimerModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit: (accepted?: boolean) => void
}

const UsBankDisclaimerModal: FC<UsBankDisclaimerModalProps> = ({
  open,
  setOpen,
  onSubmit,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={handleClose}
      showClose={false}
      size="md"
      className={classes.bankUsModal}
    >
      <Grid>
        <img
          src={bank}
          alt="bank"
          width={96}
          style={{ marginBottom: '16px' }}
        />
        <Typography className={classes.title}>
          funds transfer information
        </Typography>
        <Typography style={{ marginBottom: '12px' }}>
          The electronic funds transfer will take 24-48 hours to process from
          your bank. Your project will be scheduled upon bank transfer
          confirmation.
        </Typography>
        <Typography>
          If you would like to schedule your project sooner, we recommend paying
          by credit card.
        </Typography>
        <Button
          fullWidth
          variant="outlined"
          style={{ marginTop: 16 }}
          onClick={() => onSubmit(true)}
        >
          <Typography className={classes.buttonText}>
            Ok. I understand
          </Typography>
        </Button>
      </Grid>
    </Modal>
  )
}

export default UsBankDisclaimerModal
