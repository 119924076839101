import { Stepper } from 'components/UI'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  USER_TYPE,
  companyRoles,
  history,
} from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import {
  getApprovalStep,
  getApprovalValues,
  getContactInEstimate,
  getEstimate,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { approvalActions } from 'ducks/actions'
import { ApprovalValue } from 'ducks/approval/types'
import Step1Homeowner from './Step1/Step1'
import Step3Homeowner from './Step3/Step3'
import Step4Homeowner from './Step4/Step4'
import { StepType } from 'components/UI/CustomUI/organisms/Stepper/types'
import { useEffect } from 'react'
import UnclickableStep from '../../components/UnclickeableStep'
import ContactBanner from '../../components/ContactBanner'
import Step4Agent from '../Agent/Step4/Step4'

const HomeownerStepper = () => {
  const dispatch = useDispatch()
  const push = history.usePushForward()
  const activeStep = useSelector(getApprovalStep)
  const { agentAsHomeownerRepresentative, paymentOption, role } = useSelector(
    getApprovalValues()
  )
  const estimate = useSelector(getEstimate())
  const estimateAgent = estimate?.properties?.contacts?.find((c) =>
    companyRoles.includes(c.role as CONTACT_ROLE)
  )
  const userEmail = useSelector(getUserPrimaryEmail)
  const approvalInProgress = estimate?.properties.contacts?.some(
    (contact) => contact?.email !== userEmail && contact?.approvalFlowInProgress
  )

  const user = useSelector(getContactInEstimate(userEmail))
  const isAgent = companyRoles.includes(user?.role as CONTACT_ROLE)

  const isHomewardCXSupport = [
    'cxsupport@test.com',
    'cxsupport@homeward.com',
  ].includes(userEmail)

  const steps: StepType[] = [
    {
      content: (
        <UnclickableStep>
          <Step1Homeowner />
        </UnclickableStep>
      ),
      disableBack: true,
    },
  ]

  steps.push({
    content: <Step3Homeowner />,
    disableBack: true,
  })

  if (agentAsHomeownerRepresentative === 'myself') {
    steps.push({
      content: <Step4Agent />,
      disableBack: true,
    })
  }

  if (paymentOption !== 'pay_at_close') {
    steps.push({
      content: <Step4Homeowner />,
      nextText: '',
      actionBack: () =>
        dispatch(approvalActions.setApprovalStep(activeStep - 1)),
      disableBack: true,
    })
  }

  useEffect(() => {
    if (
      estimate?.properties?.payAtClose &&
      (estimateAgent?.approvalType === APPROVAL_TYPE.REQUIRED ||
        estimateAgent?.approvalType === APPROVAL_TYPE.NOT_NEEDED) &&
      role !== CONTACT_ROLE.AGENT_HOMEOWNER &&
      !isAgent
    ) {
      push('success')
    }
  }, [estimate?.properties?.payAtClose])

  return (
    <>
      <ContactBanner />

      <Stepper
        showHeader={!isHomewardCXSupport}
        steps={steps}
        activeStep={approvalInProgress ? 0 : activeStep}
        setActiveStepAction={(step: number) =>
          dispatch(approvalActions.setApprovalStep(step))
        }
        saveNewValueAction={(value: ApprovalValue) =>
          dispatch(approvalActions.setApprovalValue(value))
        }
      />
    </>
  )
}

export default HomeownerStepper
