import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import useStyles from './styles'
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate } from 'ducks/selectors'
import { Item } from 'ducks/types'
import { icons } from 'assets'
import { MultilineText } from 'components/UI'
import { estimateActions } from 'ducks/actions'
import { isMobile } from 'react-device-detect'
import { ESTIMATE_STATUS, ITEM_STATUS } from 'helpers/constants'


export interface AdditionalItemsRequestedProps {
  punchlistPlanner?: boolean
  serviceable?: boolean
  handleShowAlert?: (item: Item) => void
  handleRemove?: (estimateId: string, itemId: string, removed: boolean) => void
  estimateNotApproved?: boolean
}

const AdditionalItemsRequested: FC<AdditionalItemsRequestedProps> = ({ serviceable, punchlistPlanner, handleShowAlert, handleRemove, estimateNotApproved }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(true)
  const estimate = useSelector(getEstimate())
  const [newItem, setNewItem] = useState(estimate?.properties?.clientRequestNotes || '')
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const handleClick = () => {
    if (expanded) {
      setLoadingSave(true)
      handleSubmit(false)
    } else {
      setExpanded(true)
    }
  }

  const handleSubmit = (remove: boolean) => {
    /* dispatch(estimateActions.updateEstimateByPath([{ op: 'add', path: `/properties/clientRequestNotes`, value: remove ? '' : newItem }],
      (succ: boolean) => {
        setLoadingSave(false)
        setLoadingDelete(false)
      })) */
    dispatch(estimateActions.requestMoreItems(remove ? '' : newItem,
      (succ: boolean) => {
        setLoadingSave(false)
        setLoadingDelete(false)
      }))
  }


  return (
    <Box className={`${classes.root} ${expanded ? classes.rootExpanded : ''}`}>
      <Grid item className={classes.header}>
        <Typography className={classes.title}>Additional items requested</Typography>
        <Typography className={classes.subtitle}>Our team will review your request and respond within one business day.</Typography>
      </Grid>
      {estimateNotApproved &&
        <Grid container className={classes.buttonsContainer}>
          <Grid item xs={6}>
            <Button
              onClick={handleClick}
              className={classes.buttonEdit}
              startIcon={loadingSave ? <></> : (expanded ? <icons.Save /> : <icons.Edit />)}
            >
              {loadingSave ?
                <CircularProgress size={20} />
                : expanded ? 'Save' : 'Edit'
              }
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                handleSubmit(true)
                setLoadingDelete(true)
              }}
              className={classes.buttonDelete}
              startIcon={<icons.Delete />}
            >
              {loadingDelete ?
                <CircularProgress size={20} />
                : 'Delete'
              }
            </Button>
          </Grid>
        </Grid>
      }

      <Box hidden={!expanded} className={classes.container}>
        <MultilineText
          value={newItem}
          onChange={(event: any) => {
            setNewItem(event.target.value)
          }}
          className={classes.itemsInput}
          disabled={loadingSave || loadingDelete || !estimateNotApproved}
        />
      </Box>
      {!isMobile && <Box className={classes.expandButtonContainer}>
        <Button
          onClick={() => setExpanded(!expanded)}
          className={classes.expandButton}
        >
          <Typography>
            <icons.ExpandLess className={expanded ? classes.iconOpened : classes.iconClosed} />
            {expanded ? 'Collapse' : 'Expand'}
          </Typography>
        </Button>
      </Box>}
    </Box>
  )
}

export default AdditionalItemsRequested
