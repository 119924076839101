/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import { FC, useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress
} from '../../../../../../UI'
import useStyles from './styles'
import { SpecialAreasModalProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getNewEstimateValue, getTerritories, getTerritory, getUser } from '../../../../../../../ducks/selectors'
import { estimateActions, subscriptionActions, territoriesActions } from '../../../../../../../ducks/actions'
import { icons, blueLogo } from '../../../../../../../assets'
import { useIsXsScreen } from '../../../../../../../hooks'
import { history } from 'helpers/index'

const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
const defaultCenter = { lat: 40, lng: -99 }

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
  borderRadius: '8px'
}

const SpecialAreasModal: FC<SpecialAreasModalProps> = ({ open, setOpen, area }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()
  const [loading, setLoading] = useState(false)
  const user = useSelector(getUser)
  const mainContact = useSelector(getNewEstimateValue("mainContact"))
  const query = history.useQuery()
  const source = query.get('utm_source') || query.get('utmsource') || ""
  const medium = query.get('utm_medium') || ""
  const campaign = query.get('utm_campaign') || ""
  const content = query.get('utm_content') || ""

  const savedAddress = useSelector(getNewEstimateValue('address'))


  const territory = useSelector(getTerritory())

  const isAustin = area === 'Austin'

  const handleClose = () => {
    setOpen()
  }
  
  const handleSubscribeToNotification = () => {
    setLoading(true)

    dispatch(
      estimateActions.addEstimateToNotificationList((succ) => {
        setLoading(false)
        if (succ) {
          setOpen()
        }
      },
        {
          firstName: user.firstName || mainContact?.firstName || "",
          lastName: user.lastName || mainContact?.lastName || "",
          email: user.email?.[0]?.email || mainContact?.email || "",
          phone: user.phone?.toString() || mainContact?.phone || "",
          marketing: { source, medium, campaign, content },
          address: `${savedAddress.line_1} ${savedAddress.line_2} ${savedAddress.city}, ${savedAddress.state} ${savedAddress.zip_code}, ${savedAddress.country}`,
          territoryId: territory?.id || "",
          resourceType: "ESTIMATE"
        }
      )
    )
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <img src={blueLogo} style={{ height: '50px' }} alt='' />
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          className={classes.close}
          onClick={handleClose}
        >
          <icons.Close />
        </IconButton>
      </DialogContent>
      <DialogContent className={classes.content}>
        <Box className={classes.contentBody}>
          <Typography className={classes.subtitle}>{isAustin ? "Thank you for starting an estimate request with BOSSCAT™ Home Services. For the Austin area, we currently offer free inspection repair estimates. We have paused on accepting new repair orders until 2024." : "Thank you for starting an estimate request with BOSSCAT™ Home Services. For the metro DC area, we currently offer free inspection repair estimates. We have paused on accepting new repair orders until 2024."}</Typography>
          <Typography className={classes.subtitle}>{"Please click here to receive updates and be notified when we resume our full-suite of services."}</Typography>
          <Button
            variant="contained"
            className={classes.button}
            endIcon={!loading && <icons.CheckCircle className={classes.icon} />
            }
            onClick={handleSubscribeToNotification}
          >
            {loading
              ? <CircularProgress />
              : 'I want to be notified'
            }
          </Button>

        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SpecialAreasModal
