import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { DesktopPage, PrivateLoader, ScrollWrapper } from '../../../../templates'
import useStylesBetter from './stylesBetter'
import { Box, Button, DownloadButton, StatusBar, Typography } from '../../../../UI'
import { history, isEmpty, ITEM_STATUS, JOB_ITEM_PUBLIC_STATUS, JOB_PAYMENT_STATUS, JOB_PUBLIC_STATUS, JOB_VIEW, round, sortItemsByCategories } from '../../../../../helpers'
import Progress from './Progress'
import { icons, newEstimate, betterInspect } from '../../../../../assets'
import { STATUS_BAR_STATE } from '../../../../UI/CustomUI/molecules/StatusBar/types'
import JobItemsByStatus from './JobItemsByStatus'
import JobItemsByCategory from './JobItemsByCategory'
import { useDispatch, useSelector } from 'react-redux'
import { getJob, getJobAddress, getJobItems, getJobItemsGroupBy, getUser } from '../../../../../ducks/selectors'
import { jobActions, jobItemsActions } from '../../../../../ducks/actions'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from '../../../../../hooks'
import { Item } from '../../../../../ducks/types'
import StatusHeader from './StatusHeader'

import useStyles from './styles'
import html2canvas from 'html2canvas'

import * as Sentry from '@sentry/react'
import { generatePdf } from './ItemsPdf'
import { ArrowBack } from '@material-ui/icons'


const getStatusBar = (actualPosition: number): any[] => {
  const list = [
    { text: 'Scheduling', state: STATUS_BAR_STATE.TODO },
    { text: 'Dispatched', state: STATUS_BAR_STATE.TODO },
    { text: 'In Progress', state: STATUS_BAR_STATE.TODO },
    { text: 'Completed', state: STATUS_BAR_STATE.TODO }
  ]

  list.forEach((_elem, index) => {
    if (index < actualPosition) list[index].state = STATUS_BAR_STATE.DONE
    if (index === actualPosition) list[index].state = STATUS_BAR_STATE.ACTUAL
  })

  return list
}

const JobView: FC = () => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const dispatch = useDispatch()
  const push = history.usePush()
  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen
  const userInfo = useSelector(getUser)


  const { id: jobId } = history.getParamValues()
  const job = useSelector(getJob())
  const jobAddress = useSelector(getJobAddress)
  const { line_1, city, state } = job?.properties.address || {}

  const { properties, estimateId, startedOn, createdOn, preferredCompletedOn, expectedCompletedOn, completedOn, publicStatus, percentItemsComplete, status, paymentStatus } = job ?? {}
  const { publicId, totalWithTax = 0, affiliate, invoiceId, taxAmount, taxRate, nar, contacts } = properties ?? {}

  const showBetterStyle = !isEmpty(affiliate) && affiliate === 'better'
  const classes = showBetterStyle ? stylesBetter : styles


  const ownUser = contacts?.find(contact => contact.email === userInfo.email[0].email)
  const showInvoice = ownUser?.isPayer || ownUser?.sendEstimate || ownUser?.isRequester || ownUser?.role === "HOMEOWNER"


  const [pageLoading, setPageLoading] = useState(true)
  const [itemsLoading, setItemsLoading] = useState(false)
  const [view, setView] = useState<JOB_VIEW>(JOB_VIEW.STATUS)
  const [statusList, setStatusList] = useState<any>([])
  const [downloadingPdf, setDownloadingPdf] = useState(false)

  const items = useSelector(getJobItems())
  const itemsRejected = items?.filter((item: Item) => item.status === ITEM_STATUS.REJECTED)
  const itemsTotal = (items?.length - itemsRejected.length) || 0
  const itemsCompleted = percentItemsComplete ? Math.round((percentItemsComplete * itemsTotal) / 100) : 0

  const groupedItems = sortItemsByCategories(useSelector(getJobItemsGroupBy(view)))

  useEffect(() => {
    if (publicStatus === JOB_PUBLIC_STATUS.NEEDS_ATTENTION) {
      setStatusList([{ text: 'Needs Attention', state: STATUS_BAR_STATE.WARNING }])
    } else if (publicStatus === JOB_PUBLIC_STATUS.HOLD) {
      setStatusList([{ text: 'Hold', state: STATUS_BAR_STATE.HOLD }])
    } else {
      let statusPosition = -1
      if (publicStatus === JOB_PUBLIC_STATUS.SCHEDULING) statusPosition = 0
      if (publicStatus === JOB_PUBLIC_STATUS.DISPATCHED) statusPosition = 1
      if (publicStatus === JOB_PUBLIC_STATUS.IN_PROGRESS) statusPosition = 2
      if (publicStatus === JOB_PUBLIC_STATUS.COMPLETED) statusPosition = 3
      setStatusList(getStatusBar(statusPosition))
    }
  }, [publicStatus])

  const fetchJobItems = useCallback((loadings = true) => {
    if (loadings) setItemsLoading(true)
    dispatch(jobItemsActions.fetchJobItems(jobId,
      (_succ: boolean) => { setItemsLoading(false) }))
  }, [dispatch, jobId])

  const fetchJobView = useCallback((loadings = true) => {
    if (loadings) setPageLoading(true)
    dispatch(jobActions.fetchJob(jobId,
      (_succ: boolean) => {
        setPageLoading(false)
        if (!_succ) {
          push(`p/dashboard`)
          //goBack()
        } else {
          fetchJobItems(loadings)
        }
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jobId, fetchJobItems])

  useEffect(() => { fetchJobView() }, [fetchJobView])

  const handleGoToEstimate = () => {
    push(`p/estimates/${estimateId}`)
  }

  const handlePdf = async () => {
    if (!downloadingPdf) {
      setDownloadingPdf(true)
      const userInfoGrid = document.getElementById(
        'user-and-item-information'
      ) as any as HTMLElement

      const punchlistLogo = userInfoGrid?.children[0] as HTMLElement

      let doughnutChartNode: null | HTMLElement = null
      let chartCanvas: HTMLCanvasElement | null = null
      try {
        //* Deletion of the collapse button for the image in the chart
        doughnutChartNode = document.getElementById('doughnut-chart-full-data')
        const doughnutCharGridNode = doughnutChartNode?.childNodes[1]
        const collapseButton = doughnutCharGridNode?.childNodes[0]
        doughnutCharGridNode?.removeChild(collapseButton!)
        //* -------------------------------------------- *\\

        chartCanvas = await html2canvas(doughnutChartNode!)
        doughnutCharGridNode?.insertBefore(
          collapseButton!,
          doughnutCharGridNode?.firstChild
        )
      } catch (error) {
        Sentry.captureException(error);
        // console.log(`%cError: %c${error}`, `font-size: 18px; font-weight: 600;`, `color: rgb(244, 17, 21);`)
      }

      const [
        punchlistLogoCanvas,
      ] = await Promise.all([
        html2canvas(punchlistLogo!),
      ])

      const punchlistLogoUri = punchlistLogoCanvas.toDataURL('image/png', 1.0)
      const chartUri = chartCanvas?.toDataURL('image/png', 1.0)
      await generatePdf({
        groupedItems: groupedItems,
        jobInfo: {
          name: `change this`,
          address: jobAddress,
          startedOn: startedOn,
          estimateId: publicId,
          taxRate: taxRate,
          taxAmount: taxAmount,
          totalWithTax: totalWithTax,
          nar: nar,
          totalPrice: `$${round(totalWithTax ?? 0, 2)}`
        },
        imageUris: {
          punchlistLogoUri,
          chartUri,
        },
        showBetterStyle
      })
      setDownloadingPdf(false)
    }
  }

  const handleGoBack = () => {
    push('p/jobs/')
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <DesktopPage
        header={(
          <Box className={classes.titleContainer} id='user-and-item-information'>
            {smallScreen && <Box onClick={handleGoBack}>
              <ArrowBack style={{ color: 'var(--bosscat-black-600)' }} />
            </Box>}
            <Box className={classes.title}>
              <img src={betterInspect} alt='' className={classes.logo} />
              {xsScreen &&
                <Box className={classes.addressBox}>
                  <Typography variant='body2' className={classes.description}>{line_1}</Typography>
                  <Typography variant='caption' className={classes.description}>{`${city}, ${state}`}</Typography>
                </Box>}
            </Box>
            {!xsScreen && <Typography className={classes.address}>{jobAddress}</Typography>}
          </Box>)}
      >
        <Box className={classes.root}>
          {xsScreen
            ? <Box className={classes.addressBox}> <Typography variant='caption' className={classes.text}>{publicStatus} </Typography>
              <Typography className={classes.total}> {`$${round(totalWithTax ?? 0, 2)}`} </Typography>
            </Box>
            : <StatusBar statusList={statusList} showBetterStyle={showBetterStyle} />}

          {!xsScreen && <Box className={classes.header}>
            <Box className={classes.headerColumn}>
              <StatusHeader
                startedOn={startedOn}
                completedOn={completedOn}
                preferredCompletedOn={preferredCompletedOn}
                publicId={publicId}
                status={status}
                expectedCompletedOn={expectedCompletedOn}
                createdOn={createdOn}
              />
              <Box className={classes.headerRow}>
                <Box className={classes.progressBox}>
                  <Progress
                    className={classes.progress}
                    value={(!itemsLoading && itemsCompleted) || 0} total={(!itemsLoading && itemsTotal) || 0}
                    showBetterStyle={showBetterStyle}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.headerColumn} style={{ gap: '8px', minWidth: '225px' }}>
              <Box className={classes.headerRow} style={{ flexWrap: 'nowrap', gap: '4px', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <Typography className={classes.totalPrice}> ${round(totalWithTax ?? 0, 2)} </Typography>
                <Box
                  className={classes.estimateButton}
                  onClick={handleGoToEstimate}
                >
                  <img src={newEstimate} className={classes.icon} alt='' />
                  <Typography variant='caption'> Estimate </Typography>
                </Box>
                <DownloadButton
                  text='PDF'
                  onClick={handlePdf}
                  showBetterStyle={showBetterStyle}
                />
              </Box>

              <Box className={classes.headerRow}>
                <Button
                  type='submit' variant='text' size='small'
                  className={view === JOB_VIEW.CATEGORY ? classes.comboSelected : classes.combo}
                  endIcon={<icons.KeyboardArrowDown className={view === JOB_VIEW.CATEGORY ? classes.iconSelected : classes.iconCombo} />}
                  onClick={() => setView(JOB_VIEW.CATEGORY)}
                >
                  <Typography>
                    Category
                  </Typography>
                </Button>
                <Button
                  type='submit' variant='text' size='small'
                  className={view === JOB_VIEW.STATUS ? classes.comboSelected : classes.combo}
                  endIcon={<icons.KeyboardArrowDown className={view === JOB_VIEW.STATUS ? classes.iconSelected : classes.iconCombo} />}
                  onClick={() => setView(JOB_VIEW.STATUS)}
                >
                  <Typography>
                    Status
                  </Typography>
                </Button>
              </Box>

            </Box>
          </Box>}

          {xsScreen && <Box className={classes.header}>
            <Box className={classes.headerRow} style={{ gap: '4px', justifyContent: 'space-between' }}>
              <StatusHeader
                startedOn={startedOn}
                completedOn={completedOn}
                preferredCompletedOn={preferredCompletedOn}
                publicId={publicId}
                status={status}
                expectedCompletedOn={expectedCompletedOn}
                createdOn={createdOn}
              />
              <Box display="flex">
                <Box className={classes.estimateButton} onClick={handleGoToEstimate}>
                  <img src={newEstimate} className={classes.icon} alt='' />
                  <Typography variant='caption'> Estimate </Typography>
                </Box>
                <DownloadButton
                  text='PDF'
                  onClick={handlePdf}
                  showBetterStyle={showBetterStyle}
                  className={classes.downloadButtonMobile}
                />
              </Box>
            </Box>

            <Box className={classes.headerRow}>
              <Box className={classes.progressBox}>
                <Progress
                  className={classes.progress}
                  value={(!itemsLoading && itemsCompleted) || 0} total={(!itemsLoading && itemsTotal) || 0}
                  showBetterStyle={showBetterStyle} hideItems
                />
              </Box>
            </Box>

            <Box className={classes.headerRow} style={{ gap: '0px', justifyContent: 'space-between' }}>
              <Button
                type='submit' variant='text' size='small'
                className={view === JOB_VIEW.CATEGORY ? classes.comboSelected : classes.combo}
                endIcon={<icons.KeyboardArrowDown className={view === JOB_VIEW.CATEGORY ? classes.iconSelected : classes.iconCombo} />}
                onClick={() => setView(JOB_VIEW.CATEGORY)}
              >
                <Typography> Category </Typography>
              </Button>
              <Button
                type='submit' variant='text' size='small'
                className={view === JOB_VIEW.STATUS ? classes.comboSelected : classes.combo}
                endIcon={<icons.KeyboardArrowDown className={view === JOB_VIEW.STATUS ? classes.iconSelected : classes.iconCombo} />}
                onClick={() => setView(JOB_VIEW.STATUS)}
              >
                <Typography> Status </Typography>
              </Button>
            </Box>
          </Box>}

          <PrivateLoader loading={itemsLoading} building='fragment'>
            <ScrollWrapper>
              {Object.keys(groupedItems).map(itemKey => {
                const items = groupedItems[itemKey]
                const itemsWithoutRejected = items.filter((item: Item) => item.status !== ITEM_STATUS.REJECTED)
                return (
                  <Fragment key={itemKey}>
                    {view === JOB_VIEW.STATUS
                      ? (
                        <JobItemsByStatus
                          status={itemKey as JOB_ITEM_PUBLIC_STATUS}
                          showBetterStyle={showBetterStyle}
                        >
                          {itemsWithoutRejected as Item[]}
                        </JobItemsByStatus>
                      )
                      : (
                        <JobItemsByCategory
                          category={itemKey}
                          showBetterStyle={showBetterStyle}
                        >
                          {itemsWithoutRejected.filter((item: Item) => item.category === itemKey) as Item[]}
                        </JobItemsByCategory>
                      )}
                  </Fragment>
                )
              })}
            </ScrollWrapper>
            {paymentStatus !== JOB_PAYMENT_STATUS.PAID ? (
              <Box
                className={`${classes.buttonContainer} `}
              >
                <Button
                  type='submit'
                  variant='contained'
                  className={`${classes.button} `}
                  onClick={() => {
                    push(`p/jobs/${jobId}/invoice/${invoiceId}`)
                  }}
                  disabled={invoiceId === null || !showInvoice}
                >
                  Pay
                </Button>
              </Box>
            )
              : <></>
            }
          </PrivateLoader>
        </Box>
      </DesktopPage>
    </PrivateLoader>
  )
}

export default JobView
