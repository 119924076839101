import { Stepper } from 'components/UI'
import { history } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import {
  getApprovalStep,
  getApprovalValues,
  getEstimate,
} from 'ducks/selectors'
import { approvalActions } from 'ducks/actions'
import { ApprovalValue } from 'ducks/approval/types'
import Success from '../../components/Success'
import { useState } from 'react'
import Step1SecondaryHomeowner from './Step1/Step1Secondary'
import Step2SecondaryHomeowner from './Step2/Step2Secondary'
import Step3SecondaryHomeowner from './Step3/Step3Secondary'

const HomeownerSecondApprovalStepper = () => {
  const goBack = history.useGoBack()
  const dispatch = useDispatch()
  const activeStep = useSelector(getApprovalStep)
  const estimate = useSelector(getEstimate())

  const handleBack = (): void => {
    goBack()
  }

  const steps = [
    {
      content: <Step1SecondaryHomeowner />,
    },
    {
      content: <Step2SecondaryHomeowner />,
      disableBack: true,
      // actionNext: () => setIsFinished(true),
    },
  ]

  if (!estimate?.properties?.payAtClose) {
    steps.push({
      content: <Step3SecondaryHomeowner />,
      disableBack: true,
    })
  }
  return (
    <>
      <Stepper
        steps={steps}
        activeStep={activeStep}
        setActiveStepAction={(step: number) =>
          dispatch(approvalActions.setApprovalStep(step))
        }
        saveNewValueAction={(value: ApprovalValue) =>
          dispatch(approvalActions.setApprovalValue(value))
        }
        //className={classes.stepper}
      />
    </>
  )
}

export default HomeownerSecondApprovalStepper
