import { Button, Grid, Typography } from '@material-ui/core'
import { ApprovalSuccessImg } from 'assets/index'
import useStyles from '../styles'
import { ArrowBack } from '@material-ui/icons'
import {
  getApprovalValues,
  getContactInEstimate,
  getEstimate,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { APPROVAL_TYPE, CONTACT_ROLE } from 'helpers/constants'
import { history, homeownerRoles } from 'helpers/index'
import { useCallback, useEffect, useState } from 'react'
import { estimateActions } from 'ducks/actions'
import { PrivateLoader } from 'components/templates'

const ApprovalSuccess = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)
  const push = history.usePush()
  const replace = history.useReplace()
  const [pageLoading, setPageLoading] = useState(true)
  const { id: estimateId } = history.getParamValues()
  const { agentAsHomeownerRepresentative } = useSelector(getApprovalValues())

  const contactInEstimate = useSelector(getContactInEstimate(userEmail))

  const isHomeowner = homeownerRoles.includes(contactInEstimate?.role as CONTACT_ROLE)
  const secondApproval =
    estimate?.approvalStatus === 'PENDING_FURTHER' &&
    contactInEstimate?.approvalType === APPROVAL_TYPE.REQUIRED

  if (!estimate || !contactInEstimate) {
    push('p/estimates')
  }

  let message = ''
  let title = 'THANK YOU FOR ORDERING YOUR PROJECT!'
  let subtitle = ''

  if(estimate?.approvalStatus === 'APPROVED'){
    title = 'Thank you for approving the estimate!'
    message = 'Your project coordinator will be in touch to finalize the project start date.'
  }

  if (estimate?.approvalStatus === 'PROCESSING_ACH_PAYMENT') {
    title =
      'Thank You! It will take 24-48 hours to receive the payment from your bank.'
    message =
      'Once we receive the payment, we will follow up with you to schedule your project. We look forward to getting started!'
  } else if (isHomeowner) {
    if (
      estimate?.properties?.payAtClose &&
      estimate?.approvalStatus !== 'APPROVED'
    ) {
      title = 'Thank you for selecting our Pay-At-Close payment option.'
      message =
        'We will contact your real estate agent to initiate the pay-at-close process.'
      subtitle =
        'Once we receive the required documents from your agent, we will follow up with you to review and sign the service agreement.'
    } else if (secondApproval) {
      message = `You'll recieve a confirmation email shortly. Your Project Coordinator will contact you within 24 business hours to begin the scheduling process.`
    } else {
      message =
        'A BOSSCAT project coordinator will be in touch to finalize the project start date.'
    }
  } else {
    if (agentAsHomeownerRepresentative === 'myself') {
      title = 'Thank you for your order!'
      message =
        'A BOSSCAT project coordinator will be in touch to finalize the project start date.'
    } else {
      title = 'Thank you!'
      message =
        'We will now ask your client to review and sign the service agreement. Once your client completes the process, you will receive an email confirming the order.'
    }
  }

  const fetchEstimate = useCallback(
    () => {
      setPageLoading(true)

      dispatch(
        estimateActions.fetchEstimate(estimateId, (succ: boolean) => {
          if (succ) {
            // dispatch(approvalActions.cleanApproval())
            setPageLoading(false)
          } else {
            push('p/estimates')
          }
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId]
  )

  useEffect(() => {
    fetchEstimate()
    //dispatch(approvalActions.cleanApproval())
  }, [fetchEstimate])

  return (
    <PrivateLoader loading={pageLoading}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: '100%' }}
      >
        <img
          src={ApprovalSuccessImg}
          alt="approval_success"
          style={{
            borderRadius: '8px',
            border: '2px solid #F5F6F7',
            boxShadow: '0px 0px 1px 0px #0919314F, 0px 1px 1px 0px #0919313D',
            marginBottom: '24px',
          }}
        />
        <Typography
          className={styles.title}
          style={{ marginBottom: '8px', textAlign: 'center', maxWidth: '40%' }}
        >
          {title}
        </Typography>
        <Typography
          className={styles.latoPrimaryText}
          style={{
            textAlign: 'center',
            padding: 0,
            fontWeight: 400,
            marginBottom: '40px',
            maxWidth: '800px',
            width: '30%',
          }}
        >
          {message}
        </Typography>
        <Typography
          className={styles.latoPrimaryText}
          style={{
            textAlign: 'center',
            padding: 0,
            fontWeight: 400,
            marginBottom: '40px',
            maxWidth: '800px',
            width: '30%',
          }}
        >
          {subtitle}
        </Typography>
        <Button
          className={styles.greyButton}
          style={{ padding: '0 16px' }}
          startIcon={<ArrowBack style={{ width: '16px', height: '16px' }} />}
          onClick={() => replace('/p/estimates')}
        >
          <Typography className={styles.primaryLatoText}>
            Back to Estimates Panel
          </Typography>
        </Button>
      </Grid>
    </PrivateLoader>
  )
}

export default ApprovalSuccess
