import { Grid } from '../../../../../../../UI'
import useStyles from './../../../styles'
import PaymentScreen from '../../../components/PaymentScreen'
import { useStepperContext } from 'hooks/useStepperContext'
import { useEffect } from 'react'
import BottomButtons from '../../../components/BottomButtons'
import { useDispatch, useSelector } from 'react-redux'
import { configActions, estimateActions } from 'ducks/actions'
import {
  getApprovalStep,
  getContactIndexInEstimate,
  getEstimate,
  getUserId,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { history } from 'helpers/index'
import { EstimateType } from 'ducks/estimate/types'

const Step3SecondaryHomeowner = () => {
  const classes = useStyles()
  const { setShowWarnings, setCanGoForward } = useStepperContext()

  const push = history.usePushForward()
  const dispatch = useDispatch()
  const approvalStep = useSelector(getApprovalStep)
  const estimate = useSelector(getEstimate())
  const userId = useSelector(getUserId)
  const userEmail = useSelector(getUserPrimaryEmail)
  const contactInEstimateIndex = useSelector(
    getContactIndexInEstimate(userEmail)
  )

  useEffect(() => {
    setShowWarnings(false)
    setCanGoForward(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitStep = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    const payload: Partial<EstimateType> = {
      id: estimate?.id,
      properties: {
        address: estimate?.properties?.address,
        payAtClose: estimate?.properties?.payAtClose,
        accessDetails: estimate?.properties?.accessDetails,
        preferredCompletedOn: estimate?.properties?.preferredCompletedOn,
        closingDate: estimate?.properties?.closingDate,
        contacts: estimate?.properties?.contacts,
        userId: userId,
        clientNotes: estimate?.properties?.clientNotes,
        totalValue: estimate?.properties?.totalValue ?? 0,
        approvalStep: 0,
      },
    }
    dispatch(
      estimateActions.approval(payload, (succ) => {
        dispatch(
          configActions.setConfigValue({
            type: 'overlayLoading',
            value: false,
          })
        )
        if (succ) {
          succ && push(`success`)
        }
      })
    )
  }

  return (
    <Grid className={classes.container} spacing={2}>
      <PaymentScreen />
      {/* <BottomButtons
        buttonBack={{
          title: 'Back',
          action: () => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )

            dispatch(
              estimateActions.updateEstimateProperties(
                {
                  approvalStep: approvalStep - 1,
                },
                (succ) => {
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
                }
              )
            )
          },
          // disable: true,
        }}
        buttonNext={{
          title: 'Schedule My Project!',
          action: submitStep,
        }}
      /> */}
    </Grid>
  )
}

export default Step3SecondaryHomeowner
