import { FC } from 'react'
import { DetailsProps } from './types'
import useStyles from './styles'
import { blueHouseLogo } from '../../../../../assets'
import { Typography, Box, Grid } from '@material-ui/core'
import DownloadButton from '../DownloadButton'
import { BOSSCAT_PHONE_NUMBER } from 'helpers/constants'

const InvoiceHeader: FC<DetailsProps> = ({
  title, addressLine1, addressLine2, mail, invoice, phone, onClick
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} id='user-and-item-information'>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4} justifyContent='flex-start'>
          <Box className={classes.rows}>
            <img src={blueHouseLogo} alt='' className={classes.logo} />
            <Box className={classes.column}>
              <Typography variant='subtitle2' className={classes.title}> {title} </Typography>
              <Typography variant='caption' className={classes.text}> {addressLine1} </Typography>
              <Typography variant='caption' className={classes.text}> {addressLine2} </Typography>
              <Typography variant='caption' className={classes.textHigh}> {phone} </Typography>
              <Typography variant='caption' className={classes.textHigh}> {mail} </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item container xs={12} lg={4} justifyContent='center'>
          <Box className={classes.column} style={{ width: 'fit-content' }}>
            <Typography variant='caption' className={classes.title} style={{ fontWeight: 700 }}>BOSSCAT Inc</Typography>
            <Typography variant='caption' className={classes.text}>22 Westedge Street, Suite 510</Typography>
            <Typography variant='caption' className={classes.text}>Charleston, South Carolina 29403</Typography>
            <Typography variant='caption' className={classes.textHigh}>{BOSSCAT_PHONE_NUMBER}</Typography>
            <Typography variant='caption' className={classes.textHigh}>tech@bosscathome.com</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} lg={4} justifyContent='flex-end'>
          <Box className={classes.rows} style={{ justifyContent: 'flex-end' }}>
            <Typography variant='subtitle2' className={classes.totalValue}> {invoice} </Typography>
            <DownloadButton
              text='PDF'
              onClick={onClick}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InvoiceHeader
