import env from '@beam-australia/react-env'
import { Grid } from '../../../../../../../UI'
import TOSDocumentSign from '../../../components/TOSDocumentSign'
import useStyles from './../../../styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  getApprovalStep,
  getContactInEstimate,
  getContractType,
  getDocusignEnv,
  getEstimate,
  getEstimateItems,
  getUser,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { useStepperContext } from 'hooks/useStepperContext'
import { useCallback, useEffect } from 'react'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions, estimateItemsActions } from 'ducks/actions'
import { history, isEmpty, round } from 'helpers/index'
import { EstimateType } from 'ducks/estimate/types'

const Step2SecondaryHomeowner = () => {
  const classes = useStyles()
  const user = useSelector(getUser)
  const estimateItems = useSelector(getEstimateItems(true))
  const docusignEnv = getDocusignEnv()
  const push = history.usePushForward()
  const { id: estimateId } = history.getParamValues()

  const { setShowWarnings, setCanGoForward } = useStepperContext()

  const approvalStep = useSelector(getApprovalStep)
  const estimate = useSelector(getEstimate())

  const userEmail = useSelector(getUserPrimaryEmail)
  const contactInEstimate = useSelector(getContactInEstimate(userEmail))

  const docuSigned =
    (!isEmpty(contactInEstimate?.contractSignedOn?.toString()) &&
      estimate?.properties?.contractType === getContractType()) ||
    false

  const dispatch = useDispatch()

  const validation = !!docuSigned

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanGoForward(validation)
  }, [validation])

  const items = estimateItems?.map((item) => {
    return `${item?.title?.length > 55 ? `${item?.title?.slice(0, 55)}...` : item?.title
      } - Quantity: ${item?.quantity} - $${round(item?.totalPrice)}`
  })

  const submitStep = () => {
    if (estimate?.properties?.payAtClose) {
      const payload: Partial<EstimateType> = {
        id: estimate?.id,
        properties: {
          address: estimate?.properties?.address,
          payAtClose: false,
          accessDetails: estimate?.properties?.accessDetails,
          preferredCompletedOn: estimate?.properties?.preferredCompletedOn,
          closingDate: estimate?.properties?.closingDate,
          contacts: estimate?.properties?.contacts,
          userId: user?.id,
          clientNotes: estimate?.properties?.clientNotes,
          totalValue: estimate?.properties?.totalValue ?? 0,
          approvalStep: 0,
        },
      }
      dispatch(
        estimateActions.approval(
          payload,
          (success: boolean, status: string, payAtClose) => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
            if (success) {
              dispatch(
                configActions.setConfigValue({
                  type: 'overlayLoading',
                  value: false,
                })
              )
              push(`success`)
            }
          }
        )
      )
    } else {
      dispatch(
        estimateActions.updateEstimateByPath(
          [
            {
              op: 'replace',
              path: '/properties/approvalStep',
              value: approvalStep + 1,
            },
          ],
          (succ) => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
          }
        )
      )
    }
  }

  const fetchEstimateItems = useCallback(
    () => {
      dispatch(
        estimateItemsActions.fetchEstimateItems(
          estimateId,
          (_succ: boolean) => {
          }
        )
      )
    },
    [dispatch, estimateId]
  )

  useEffect(() => {
    fetchEstimateItems()
  }, [])

  const userFullName = `${contactInEstimate?.firstName} ${contactInEstimate?.lastName}`.trim()

  return (
    <Grid className={classes.container} spacing={2}>
      <TOSDocumentSign
        onSuccess={submitStep}
        title="📋 PLEASE REVIEW AND SIGN THE SERVICE AGREEMENT"
        docuSignUrl={`
          ${docusignEnv}
          &Signer_UserName=${userFullName}&Signer_Email=${encodeURIComponent(
          userEmail
        )}&UserName=${userFullName}&UserTitle=${user?.clientType || ""}&EstimateRefId=${estimate?.properties.publicId
          }&Fee=${round(
            estimate?.properties.totalWithTax || 0,
            2
          )}&FullAddress=${estimate?.properties.fullAddress}&AddressLine1=${estimate?.properties?.address?.line_1}&AddressLine2=${estimate?.properties.address?.line_2
            ? estimate?.properties.address?.line_2
            : `${estimate?.properties.address?.city}, ${estimate?.properties.address?.state} ${estimate?.properties.address?.zipCode}`
          }${estimate?.properties.address?.line_2
            ? `&AddressLine3=${estimate?.properties.address?.city}, ${estimate?.properties.address?.state} ${estimate?.properties.address?.zipCode}`
            : ''
          }&Items1=${items.join(`%0A`)}
      `}
        docuSigned={docuSigned}
      />

      <BottomButtons
        buttonBack={{
          title: 'Back',
          action: () => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )

            dispatch(
              estimateActions.updateEstimateProperties(
                {
                  approvalStep: approvalStep - 1,
                },
                (succ) => {
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
                }
              )
            )
          },
          // disable: true,
        }}
        buttonNext={{
          title: 'Next',
          action: submitStep,
          disable: !validation,
        }}
        showNextButton={docuSigned}
      />
    </Grid>
  )
}

export default Step2SecondaryHomeowner
