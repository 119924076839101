import { Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'


import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from '../../../../../../assets'
import { authActions, configActions } from '../../../../../../ducks/actions'
import { Auth0Error } from '../../../../../../ducks/auth/types'
import { getConfigValue, getUser } from '../../../../../../ducks/selectors'
import { AUTH0_CODES, history, validateEmail } from '../../../../../../helpers'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'
import { PasswordLabel } from '../../../../../UI'
import useStyles from './styles'

const Password = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const replace = history.useReplace()

  const { showWarnings, setShowWarnings } = useStepperContext()

  const user = useSelector(getUser)
  const registerActiveStep = useSelector(getConfigValue('registerActiveStep'))

  const [password, setPassword] = useState('')
  const [passwordWarning, setPasswordWarning] = useState('')
  const [passwordPolicy, setPasswordPolicy] = useState('')

  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorCode, setErrorCode] = useState('')

  const [tosAccepted, setTosAccepted] = useState(false)

  const email = user?.email[0]?.email

  const firstName = useMemo(() => user.firstName, [])

  const validate =
    !isEmpty(email) &&
    validateEmail(email) &&
    !isEmpty(password) &&
    tosAccepted

  useEffect(() => {
    if (submit) {
      if (isEmpty(password)) {
        setPasswordWarning('Enter a password to create an account')
      } else if (errorCode === AUTH0_CODES.INVALID_PASSWORD) {
        setPasswordWarning('Password is too weak, please choose a stronger password')
      } else {
        setPasswordWarning('')
      }
    }
  }, [submit, password, errorCode, passwordPolicy])

  const noHandledErrorCode = (): boolean => {
    return !isEmpty(errorCode) &&
      errorCode !== AUTH0_CODES.INVALID_PASSWORD
  }


  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    event.preventDefault()
    setSubmit(true)
    setShowWarnings(true)
    console.log('------')
    if (validate && !loading) {
      setLoading(true)

      dispatch(authActions.signupRequest({
        email,
        password,
        additionalSignUpFields: {
          firstName: user.firstName,
          lastName: user.lastName,
        },
        callback: (success: boolean, error?: Auth0Error) => {
          if (success) {
            dispatch(authActions.loginRequest({
              email,
              password,
              remember: true,
              callback: () => {
                setLoading(false)
                replace('/')
              }
            }))
          } else {
            setErrorCode(error?.code ?? '')
            setPasswordPolicy(error?.policy ?? '')
            setLoading(false)
          }
        }
      }))
    }
  }

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  return (
    <Grid>
      <Grid item>
        <Typography variant="h5" className={classes.title}> nice to meet you, {firstName || ''}!</Typography>
        <Typography className={classes.subtitle}>Let’s create a secure password</Typography>
      </Grid>
      <Grid item>
        <PasswordLabel
          label='Password:'
          value={password}
          error={submit && !isEmpty(passwordWarning)}
          helperText={submit && passwordWarning}
          onChange={(event) => setPassword(event.target.value)}
          placeholder='Create a secure password'
          className={classes.pass}
        />
        {!isEmpty(passwordPolicy) && (
          <Typography
            variant='body2'
            color='error'
            style={{ whiteSpace: 'pre-wrap', padding: '0 16px' }}
          >
            {passwordPolicy}
          </Typography>
        )}
      </Grid>
      <Grid container item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={tosAccepted}
              onChange={() => {
                setTosAccepted(!tosAccepted)
              }}
              name="consent"
              color="primary"
            />
          }
          label={
            <Typography
              variant="body2"
              color={showWarnings && !tosAccepted ? 'error' : 'initial'}
            >
              I accept BOSSCAT’s
              <Link
                target="_blank"
                className={classes.link}
                href="https://bosscathome.com/terms/"
                rel="noreferrer"
              >
                Terms and Conditions
              </Link>
            </Typography>
          }
        />
      </Grid>
      {submit && noHandledErrorCode() &&
        <Typography
          variant='body2'
          color='error'
          style={{ padding: '0 16px' }}
        >
          An error occurred while trying to sign up, please try again.
        </Typography>
      }
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.cancel}
            onClick={() => {
              dispatch(configActions.setConfigValue({ type: 'registerActiveStep', value: registerActiveStep - 1 }))
            }}
            startIcon={<icons.ArrowBack style={{ width: '1rem' }} />}
            fullWidth
            disabled={loading}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.submit}
            onClick={handleSubmit}
            // endIcon={<Avatar alt='' src={arrow} className={classes.icon} />}
            endIcon={<icons.ArrowForward style={{ width: '1rem' }} />}
            fullWidth
            disabled={loading}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Password