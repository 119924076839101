
import React, { useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configActions } from '../../../../ducks/actions'
import { getError } from '../../../../ducks/selectors'
import ErrorPage from '../../Pages/ErrorPage'
import InitializeGoogleAnalytics from 'helpers/google-analytics'

const MainWrapper: FC = ({ children }) => {
  const dispatch = useDispatch()
  const error = useSelector(getError())

  useEffect(() => {
    if (!navigator.onLine) {
      dispatch(configActions.setError({ show: true, type: 'no_internet' }))
    } else {
      dispatch(configActions.setError({ show: false, type: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    InitializeGoogleAnalytics()
  }, [])

  window.addEventListener('online', () => {
    dispatch(configActions.setError({ show: false, type: '' }))
  })

  window.addEventListener('offline', () => {
    dispatch(configActions.setError({ show: true, type: 'no_internet' }))
  })

  return <main>{error.show ? <ErrorPage type={error.type} /> : children}</main>
}

export default MainWrapper
