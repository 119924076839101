/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useCallback, ChangeEvent } from 'react'
import ArrowOutwardIcon from '@material-ui/icons/ArrowUpwardRounded'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouteLink } from 'react-router-dom'

import {
  Box,
  Grid,
  Button,
  Typography,
  Avatar,
  Radio,
  TextFieldLabel,
  SelectAddress,
  Link,
  CircularProgress,
  RadioGroup,
} from '../../../../../UI'
import {
  icons,
  obAgent,
  obHomeowner,
  obInstitutionalClient,
} from '../../../../../../assets'
import {
  isEmpty,
  validateEmail,
  CONTACT_ROLE,
  history,
  FORM_TYPE,
  ESTIMATE_STATUS,
  USER_TYPE,
  institutionalOptions,
  agentOptions,
} from '../../../../../../helpers'
import useStyles from './styles'
import { getEstimatesServices, getNewEstimateValue, getNewEstimateValues, getUser, getUserPrimaryEmail, isAuthorized } from '../../../../../../ducks/selectors'
import {
  estimatesActions,
  newEstimateActions,
  territoriesActions,
  userActions,
} from '../../../../../../ducks/actions'
import { WorkProps } from './types'
import AddressWarningModal from './AddressWarningModal'
import ServiceAreasModal from './ServiceAreasModal'
import { useStepperContext } from 'hooks/useStepperContext'
import { Address } from 'ducks/types'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import { UserType } from 'ducks/user/types'
import { isMobile } from 'react-device-detect'
import SpecialAreasModal from './SpecialAreasModal'
import { TrackGoogleAnalyticsEvent, getSessionId } from 'helpers/google-analytics'

export const roleOptions = [
  {
    title: `I'm a Homeowner/Homebuyer`,
    icon: obHomeowner,
    id: CONTACT_ROLE.HOMEOWNER,
  },
  {
    title: `I represent a Homeowner/Homebuyer`,
    icon: obAgent,
    id: CONTACT_ROLE.LISTING_AGENT,
  },
  {
    title: `I'm an Institutional Investor`,
    icon: obInstitutionalClient,
    id: CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
  },
]

const Work: FC<WorkProps> = ({
  //  setShowRegister,
  // setShowLogin,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()
  const query = history.useQuery()

  const {
    goForward,
    showWarnings,
    setShowWarnings
  } = useStepperContext()

  const savedAddress = useSelector(getNewEstimateValue('address'))
  const mainContact = useSelector(getNewEstimateValue('mainContact'))
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const isInstitutional = clientType === CONTACT_ROLE.INSTITUTIONAL_INVESTOR
  const authorized = useSelector(isAuthorized)

  const [firstName, setFirstName] = useState(mainContact.firstName ?? '')
  const [lastName, setLastName] = useState(mainContact.lastName ?? '')
  const [address, setAddress] = useState(!isEmpty(savedAddress.line_1) ? savedAddress : estimate?.properties?.address)
  const [email, setEmail] = useState(mainContact.email ?? '')
  const [loading, setLoading] = useState(false)
  const [isAustinOrDC, setIsAustinOrDC] = useState("")

  //const [showProfessionalType, setShowProfessionalType] = useState(!isEmpty(mainContact.institutionalRole))
  const [showProfessionalType, setShowProfessionalType] = useState((clientType === CONTACT_ROLE.LISTING_AGENT || clientType === CONTACT_ROLE.INSTITUTIONAL_INVESTOR) && !authorized)

  const professionalTypeOptions = isInstitutional ? institutionalOptions : agentOptions

  const user = useSelector(getUser)
  const services = useSelector(getEstimatesServices)
  const userNar = useSelector(getNewEstimateValue('isNar'))
  const queryNar = query.get('nar')
  const isNar = userNar || queryNar === 'true'
  const userMail = useSelector(getUserPrimaryEmail)

  const newEstimate = useSelector(getNewEstimateValues())

  const source = query.get('utm_source') || query.get('utmsource')
  const medium = query.get('utm_medium')
  const campaign = query.get('utm_campaign')
  const content = query.get('utm_content')

  const [showServiceAreas, setShowServiceAreas] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [canShowError, setCanShowError] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)

  const [serviceable, setServiceable] = useState(!!newEstimate?.serviceable)

  const affiliate = query.get('affiliate')
  const queryParams = query.toString()

  const addressIsValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address.latitude &&
    address.longitude
    && !apiLoading

  useEffect(() => {
    if (newEstimate?.address?.zipCode) {
      fetchZipCodeServices(newEstimate?.address?.zipCode)
    }
  }, [])

  const validate = () => {
    if (authorized) {
      return addressIsValid
    } else {
      return !isEmpty(firstName) && !isEmpty(email) && validateEmail(email) && addressIsValid && !isEmpty(clientType) && (showProfessionalType ? professionalTypeOptions.some(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType)) : true)
    }
  }

  const updateMainContact = (attr: string, value: any) => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'mainContact',
        value: { ...mainContact, [attr]: value },
      })
    )
  }

  const handleSelect = (idx: any): void => {
    updateMainContact('role', idx)
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'clientType',
        value: idx,
      })
    )
    updateMainContact('clientType', idx)
    //updateUser('clientType', idx)
  }

  const fetchZipCodeServices = useCallback((zipCode: string) => {
    dispatch(
      territoriesActions.fetchTerritory(source ? { zipCode, source } : zipCode, (succ, terr) => {

        dispatch(
          newEstimateActions.setNewEstimateValue({
            attr: 'serviceable',
            value: terr?.serviceable || false
          })
        )

        setServiceable(terr?.serviceable || false)
        if (succ) {
          if (terr?.title === "Washington, DC" || terr?.title === "Austin") {
            setIsAustinOrDC(terr?.title)
          }
          dispatch(
            estimatesActions.fetchEstimatesServices(
              {
                formType: FORM_TYPE.CLIENT_INSPECTION_REPORT,
                serviceable: terr?.serviceable || false,
                isNar,
                affiliate
              },
              () => {
                setApiLoading(false)
              }
            )
          )
        }
      })
    )
  }, [])

  const handleChangeAddress = (newAddress: Address) => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'address',
        value: newAddress
      })
    )
    // updateUser('address', newAddress)

    if (newAddress.zipCode) {
      if (newAddress.zipCode !== address.zipCode) {
        setApiLoading(true)
        fetchZipCodeServices(newAddress.zipCode)
      }
    } else {
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'serviceable',
          value: false
        })
      )
    }
    setAddress(newAddress)
  }

  const handleNext = () => {
    if (validate() && !loading) {
      // GA4 events
      if (!authorized) {
        TrackGoogleAnalyticsEvent({
          category: 'Estimate',
          action: 'anonymous_estimate_go',
          options: {
            session_id: getSessionId(),
            timestamp: new Date().toISOString(),
          }
        })
      } else {
        TrackGoogleAnalyticsEvent({
          category: 'Estimate',
          action: 'authenticated_estimate_go',
          options: {
            email: user.email[0].email,
            timestamp: new Date().toISOString(),
          }
        })
      }

      setLoading(true)

      dispatch(
        userActions.fetchUserHead(
          {
            email: email || mainContact.email || "",
            userType: 'Client',
          },
          (succ) => {
            /*  dispatch(
               newEstimateActions.setNewEstimateValue({
                 attr: 'mainContact',
                 value: { ...mainContact, firstName, email, address, clientType },
               })
             ) */
            if (succ && !authorized) {
              push('login?redirect=new-estimate')
            } else {
              if ([CONTACT_ROLE.LISTING_AGENT, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType) && !showProfessionalType && !authorized) {
                setShowProfessionalType(true)
                setLoading(false)
              } else if (estimate.status === ESTIMATE_STATUS.NEW) {
                setLoading(true)
                dispatch(
                  newEstimateActions.createEstimateV2((succ, estimateId) => {
                    setLoading(false)
                    if (succ) {
                      dispatch(
                        newEstimateActions.setNewEstimateValue({
                          attr: 'step',
                          value: 0,
                        })
                      )
                    }
                  }, { marketingProperties: { source: source || "", campaign: campaign || "", medium: medium || "", content: content || "" }, properties: { affiliate: source || "" } })
                )
              } else if (serviceable || (!serviceable && !estimate?.serviceable)) {
                // We show the modal only if old address is serviceable and the new one isn't.
                setLoading(true)
                dispatch(
                  newEstimateActions.updateEstimateAddress(address,
                    (success, _estimateId) => {
                      if (success) {
                        dispatch(
                          newEstimateActions.setNewEstimateValue({
                            attr: 'step',
                            value: 0,
                          })
                        )
                      }
                    })
                )
              } else {
                setLoading(false)
                setShowWarningModal(true)
              }
            }
          }
        )
      )
    }
  }

  useEffect(() => {
    if (isNar || !isEmpty(affiliate)) {
      handleSelect(CONTACT_ROLE.LISTING_AGENT)
    }
  }, [])

  useEffect(() => {
    // If user is logged in we replace the mainContact with the user info
    if (user.id) {
      dispatch(newEstimateActions.setNewEstimateValue({
        attr: 'mainContact',
        value: {
          ...mainContact,
          email: userMail,
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user.phone,
          sendEstimate: true
        }
      }))
      !clientType && dispatch(newEstimateActions.setNewEstimateValue({
        attr: 'clientType',
        value: user.clientType === USER_TYPE.HOMEOWNER
          ? user.clientType
          : CONTACT_ROLE.LISTING_AGENT
      }))
    }
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'redirectAfterLogin',
        value: false
      })
    )
  }, [user])

  const handleChangeProfessionalType = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = professionalTypeOptions.find(option => option.label === e.target.value)
    updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', selectedOption?.key)

    //dispatch(userActions.setUserValue({ attr: isInstitutional ? 'institutionalRole' : 'clientType', value: selectedOption?.key }))
  }

  /* const updateUser = (attr: keyof UserType, newValue: any) => {
    dispatch(userActions.setUserValue({
      attr,
      value: newValue
    }))
  } */

  return (
    <>
      <AddressWarningModal
        open={showWarningModal}
        setOpen={setShowWarningModal}
        address={address}
        goBack={() => {
          setShowWarningModal(false)
          setShowProfessionalType(false)
        }}
        goForward={() => dispatch(
          newEstimateActions.setNewEstimateValue({
            attr: 'step',
            value: 0,
          })
        )}
      />
      {!showProfessionalType ?
        <Box className={`${isMobile ? classes.mobileContainer : classes.container} `}>
          <Box aria-label='estimate-account-work' >
            {isAustinOrDC && <SpecialAreasModal setOpen={() => setIsAustinOrDC("")} open={!!isAustinOrDC} area={isAustinOrDC as "Washington, DC" | "Austin"} />}
            {!serviceable && addressIsValid && (
              <>
                <ServiceAreasModal
                  open={showServiceAreas}
                  setOpen={setShowServiceAreas}
                />
                <Box
                  className={`${classes.locationWarning} ${classes.locationWarningBackground}`}
                >
                  <img
                    alt=''
                    src={obInstitutionalClient}
                    className={classes.locationWarningIcon}
                  />
                  <Box>
                    <Typography
                      variant='body1'
                      className={classes.locationWarningText1}
                    >
                      You can upload an inspection report, and we’ll get you an
                      estimate in {services.length > 0 ? services?.map(service => service?.title).join(' or ') : 'one day'}.
                    </Typography>
                    <Typography
                      variant='body2'
                      className={classes.locationWarningText2}
                    >
                      For the repair and renovation services, we haven't quite expanded to your area at this time. We'll let you know as soon as we do!
                    </Typography>
                    <Link
                      className={classes.locationWarningLink}
                      onClick={() => setShowServiceAreas(true)}
                    >
                      See our current service areas.
                    </Link>
                  </Box>
                </Box>
              </>
            )}
            {!authorized &&
              <>
                <Typography className={classes.title}>
                  It’s nice to meet you!
                </Typography>
                <Box className={classes.subtitleContainer}>
                  <Typography className={classes.nextArtBold}>
                    Have we already met?
                  </Typography>
                  <RouteLink className={classes.loginLink} to={`/login?redirect=new-estimate${queryParams ? `&${queryParams}` : ''}`}>
                    Log in to your account here
                  </RouteLink>
                  <ArrowOutwardIcon className={classes.iconArrow} />
                </Box>
              </>
            }
          </Box>

          <Grid container spacing={4} direction="column" alignItems='flex-end'>
            {!authorized &&
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="First Name"
                    type="text"
                    value={firstName}
                    error={showWarnings && isEmpty(firstName)}
                    onChange={(event) => {
                      setFirstName(event.target.value)
                      updateMainContact('firstName', event.target.value)
                      // updateUser('firstName', event.target.value)
                    }}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="Last Name"
                    type="text"
                    value={lastName}
                    error={showWarnings && isEmpty(lastName)}
                    onChange={(event) => {
                      setLastName(event.target.value)
                      updateMainContact('lastName', event.target.value)
                      //updateUser('lastName', event.target.value)
                    }}
                    placeholder="Last Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="Email"
                    type="email"
                    value={email}
                    error={showWarnings && (isEmpty(email) || !validateEmail(email))}
                    onChange={(event) => {
                      setEmail(event.target.value)
                      updateMainContact('email', event.target.value)
                      // updateUser('email', event.target.value)
                    }}
                    placeholder="Email"
                  />
                </Grid>
              </Grid>
            }
            <Grid item container spacing={2}>
              <Box className={classes.selectAddressContainer} aria-label='steps-location'>
                {authorized &&
                  <>
                    <Typography className={classes.title}>
                      Let's get started, {user.firstName}
                    </Typography>
                  </>
                }
                <Grid container spacing={2} justifyContent='space-between'>
                  <Grid container item xs={12} xl={12} >
                    <Grid item xs={12}>
                      <SelectAddress
                        showUnitNumberBesideAddress
                        //showVertical
                        xl={12}
                        showWarnings={canShowError && !addressIsValid}
                        onChange={handleChangeAddress}
                        title={
                          <Typography variant='subtitle2' className={classes.titleLabel}>
                            Property Address for the Estimate Request
                          </Typography>
                        }
                        placeholder='Property address'
                        hasCallback
                        savedAddress={savedAddress}
                        errorMessage={
                          <Box className={classes.addressError}>
                            <Typography>Hey{mainContact?.firstName && `, ${mainContact.firstName}`}!</Typography>
                            <Typography className={classes.addressErrorSubtitle}>This is an invalid address. Let’s modify the address to go on with your request.</Typography>
                          </Box>
                        }
                        /*  showUnit={false} */
                        showMap={false}
                        customClass={classes.selectAddress}
                        unitNumberClassName={classes.unitNumber}
                        resetOnBlur={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {!authorized && <Grid item container spacing={2}>
              <Typography className={classes.roleTitle}>
                What is your role in this Estimate request?
              </Typography>
              <Grid item container className={classes.options}>
                {roleOptions.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    className={clsx(
                      classes.option,
                      clientType === item.id && classes.selected
                    )}
                    onClick={() => {
                      updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', null)
                      handleSelect(item.id)
                    }}
                  >
                    <Box className={classes.content}>
                      <Box className={classes.details}>
                        <Typography variant="body1">
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Radio
                      color="primary"
                      disableRipple
                      checked={clientType === item.id}
                      style={{ backgroundColor: 'transparent' }}
                    // classes={{ checked: classes['Mui-checked'] }}
                    />
                  </Grid>
                ))}
                {/*     {options.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              className={clsx(
                classes.option,
                clientType === item.id && classes.selected
              )}
              onClick={() => handleSelect(item.id)}
            >
              <Box className={classes.content}>
                <Avatar
                  alt=""
                  src={item.icon}
                  className={classes.iconJob}
                  variant="square"
                />
                <Box className={classes.details}>
                  <Typography variant="body1" className={classes.subtitle}>
                    {item.title}
                  </Typography>
                  <Typography className={classes.smallInfo}>
                    {item.text}
                  </Typography>
                </Box>
              </Box>
              <Radio
                color="primary"
                disableRipple
                checked={clientType === item.id}
                style={{ backgroundColor: 'transparent' }}
                classes={{ checked: classes['Mui-checked'] }}
              />
            </Grid>
          ))} */}
              </Grid>
            </Grid>}
          </Grid>

        </Box>
        :
        <Box aria-label='estimate-account-work' >
          <Typography className={classes.title}>
            tell us about your professional type
          </Typography>
          <Grid item spacing={2} className={classes.professionalTypeOptions}>
            <RadioGroup
              value={professionalTypeOptions.find(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType))?.label}
              onChange={handleChangeProfessionalType}
            >
              {professionalTypeOptions.map((option, index) => {
                const selectedOption = professionalTypeOptions.find(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType))
                return <FormRadioButton
                  style={selectedOption?.key === option.key ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                  key={index}
                  value={option.label}
                  control={<Radio color='primary' />}
                  label={<Typography variant='caption'>{option?.label}</Typography>}
                />
              })
              }
            </RadioGroup>

          </Grid>
        </Box>
      }
      <Grid container className={classes.buttons} style={{ justifyContent: showProfessionalType ? 'space-between' : 'flex-end' }}>
        {showProfessionalType &&
          <Button
            variant="contained"
            className={classes.buttonBack}
            startIcon={<icons.ArrowBack className={classes.icon} />}
            onClick={() => {
              updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', null)
              /*   dispatch(userActions.setUserValues({
                  attrs: {
                    institutionalRole: null,
                    clientType: isInstitutional ? USER_TYPE.INSTITUTIONAL : USER_TYPE.BROKER
                  }
                })) */
              setShowProfessionalType(false)
            }}
          >
            Back
          </Button>
        }
        <Button
          variant="contained"
          className={classes.button}
          disabled={!validate()}
          endIcon={!loading ? <icons.ArrowForward className={classes.icon} /> : <></>}
          onClick={handleNext}
        >
          {loading
            ? (
              <CircularProgress
                className={classes.spinner}
                color='secondary'
                size={30}
              />
            )
            : (
              'Go'
            )}
        </Button>
      </Grid>
    </>
  )
}

export default Work
