import { Grid } from '../../../../../../../UI'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import ContactConsent from '../../../components/ContactConsent'
import ProjectCosts from '../../../components/ProjectCosts'

import VerifyContact from '../../../components/VerifyContact'
import useStyles from './styles'
import { useEffect, useMemo } from 'react'
import TOSConsent from '../../../components/TOSConsent'
import { useDispatch, useSelector } from 'react-redux'
import {
  getApprovalStep,
  getApprovalValues,
  getEstimate,
  getUser,
} from 'ducks/selectors'
import { getEstimateItems } from 'ducks/selectors'
import ItemsDetail from '../../../components/ItemsDetail'
import BottomButtons from '../../../components/BottomButtons'
import { history, round } from 'helpers/index'
import { configActions, estimateActions } from 'ducks/actions'
import { UpdateRequest } from 'api/types'

const Step1SecondaryHomeowner = () => {
  const classes = useStyles()

  const { setShowWarnings, setCanGoForward, saveNewValue } = useStepperContext()
  const items = useSelector(getEstimateItems(true))
  const itemsCount = items?.length ?? 0
  const estimate = useSelector(getEstimate())

  const user = useSelector(getUser)

  const dispatch = useDispatch()
  const { homeowner } = useSelector(getApprovalValues())
  const discountExp = (estimate?.properties?.discount || 0) * -1
  const totalProjectCost = estimate?.properties?.discount
    ? ((estimate?.properties?.totalWithTax as number) -
        (estimate?.properties?.taxAmount || 0)) /
      ((100 - discountExp) / 100)
    : estimate?.properties?.totalWithTax

  const goBack = history.useGoBack()
  const approvalStep = useSelector(getApprovalStep)

  const {
    /*  verifyContactName,
    verifyContactPhone, */
    consentTerms,
    smsConsent,
    signOk,
  } = useSelector(getApprovalValues())

  const validate =
    /* !!verifyContactName &&
    !!verifyContactPhone && */
    !!consentTerms && !!smsConsent && !!signOk

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanGoForward(validate)
  }, [validate])

  const submitStep = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )

    const homeownerIndex = estimate?.properties.contacts?.findIndex(
      (contact) => contact.email === homeowner?.email
    )

    const payload: UpdateRequest = [
      {
        op: 'replace',
        path: `/properties/contacts/${homeownerIndex}/firstName`,
        value: homeowner?.fullName?.split(' ')[0] || '',
      },
      {
        op: 'replace',
        path: `/properties/contacts/${homeownerIndex}/lastName`,
        value: homeowner?.fullName?.split(' ')[1] || '',
      },
      {
        op: 'replace',
        path: `/properties/contacts/${homeownerIndex}/phone`,
        value: homeowner?.phone,
      },
      {
        op: 'replace',
        path: `/properties/contacts/${homeownerIndex}/approvalFlowInProgress`,
        value: true,
      },
      {
        op: 'replace',
        path: `/properties/approvalStep`,
        value: approvalStep + 1,
      },
    ]

    dispatch(estimateActions.updateEstimateByPath(payload))

    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: false })
    )
  }

  return (
    <Grid className={classes.container} spacing={2}>
      <Grid
        style={{
          background: 'var(--background-color-primary)',
          borderRadius: '8px',
          padding: '1rem',
          border: '1px solid #F5F6F7',
          boxShadow: '0px 0px 1px 0px #0919314F, 0px 1px 1px 0px #0919313D',
        }}
      >
        <ProjectCosts
          depositDue={estimate?.properties?.depositPercentage || 0}
          discountPercents={estimate?.properties.discount || 0}
          totalProjectCost={totalProjectCost || 0}
          totalWithDiscount={estimate?.properties?.totalWithTax || 0}
          isPayAtClose={estimate?.properties?.payAtClose || false}
          taxAmount={estimate?.properties?.taxAmount || 0}
          taxRate={estimate?.properties?.taxRate || 0}
        />
      </Grid>

      <VerifyContact />

      <TOSConsent />

      <ContactConsent />

      <BottomButtons
        buttonBack={{
          title: 'Back to Estimate',
          action: () => {
            goBack()
          },
          // disable: true,
        }}
        buttonNext={{
          title: 'Sign Service Agreements',
          action: submitStep,
          disable: !validate,
        }}
      />
    </Grid>
  )
}

export default Step1SecondaryHomeowner
