import { FC, useEffect, useState } from 'react'
import { visaLogo, mastercardLogo } from '../../../../../assets'
import {
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  Button,
} from '../../../../UI'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../ducks/selectors'
import { PaymentMethod } from '../../../../../ducks/user/types'
import { PAYMENT_METHOD_BRAND } from '../../../../../helpers'
import NewCardModal from './NewCardModal'
import { PaymentMethodsProps } from './types'
import { isMobile } from 'react-device-detect'
// import PaymentElementCustom from 'components/UI/CustomUI/organisms/PaymentElement'

const PaymentMethods: FC<PaymentMethodsProps> = ({
  onChange,
  selected,
  addNewType = 'inline',
  openedModal = false,
  dontShowAddNew = false,
  setOpenedModal,
}) => {
  const classes = useStyles()
  const className = useClassName()

  const { paymentMethods } = useSelector(getUser)

  const [open, setOpen] = useState<boolean>(openedModal)

  const getLogo = (brand: PAYMENT_METHOD_BRAND) => {
    switch (brand) {
      case 'visa':
        return visaLogo
      case 'mastercard':
        return mastercardLogo
      default:
        return null
    }
  }

  useEffect(() => {
    if (openedModal) setOpen(true)
  }, [openedModal])

  const renderCardLabel = (card: PaymentMethod, index: number) => {
    if (!card) return null
    const lastFourDigits = card.last4
    const expirationDate = card.expirationMonth + '/' + card.expirationYear
    const logo = getLogo(card.brand)

    return (
      <Box
        className={className(classes.horizontalContainer, classes.spacedItems)}
      >
        <Box className={classes.horizontalContainer}>
          <Box className={classes.cardLogoContainer}>
            {logo && (
              <img
                alt={`Payment Card ${lastFourDigits}`}
                className={classes.cardLogo}
                src={logo}
              />
            )}
          </Box>
          <Box className={classes.cardInformationContainer}>
            <Typography
              className={className(classes.cardInformation, classes.cardType)}
            >
              {card.brand}
            </Typography>
            <Typography className={classes.cardInformation}>
              {`ending in ${lastFourDigits}`}
            </Typography>
          </Box>
        </Box>
        <Typography
          className={className(classes.cardInformation, classes.selfEnd)}
        >
          {!isMobile ? 'Expires in' : ''} {expirationDate}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.horizontalContainer}>
        <FormControl
          classes={{
            root: classes.w100,
          }}
          component="fieldset"
        >
          <RadioGroup
            onChange={onChange}
            className={classes.paymentMethodsContainer}
          >
            {paymentMethods.map((item, index) => (
              <FormControlLabel
                className={`${classes.spacedItems} ${classes.paymentMethod}`}
                style={
                  item?.stripeId === selected?.stripeId
                    ? { borderColor: 'var(--bosscat-blue-600)' }
                    : {}
                }
                control={
                  <Radio classes={{ checked: classes['Mui-checked'] }} />
                }
                checked={item?.stripeId === selected?.stripeId}
                key={index}
                label={renderCardLabel(item, index)}
                labelPlacement="start"
                value={index.toString()}
                classes={{
                  label: classes.w100,
                  root: classes.w100,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      {addNewType === 'modal' && (
        <>
          <NewCardModal
            open={open}
            setOpen={(open) => {
              setOpen(open)
              setOpenedModal && setOpenedModal(open)
            }}
          />
          {!dontShowAddNew && (
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
              className={classes.addPaymentMethodButton}
            >
              Add new Payment Method
            </Button>
          )}
        </>
      )}
    </Box>
  )
}

export default PaymentMethods
