/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import { FC, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from '../../../../../../UI'
import useStyles from './styles'
import { ServiceAreasModalProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getTerritories } from '../../../../../../../ducks/selectors'
import { territoriesActions } from '../../../../../../../ducks/actions'
import { icons, blueLogo } from '../../../../../../../assets'
import { useIsXsScreen } from '../../../../../../../hooks'

const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
const defaultCenter = { lat: 40, lng: -99 }

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
  borderRadius: '8px',
}

const ServiceAreasModal: FC<ServiceAreasModalProps> = ({ open, setOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()

  const territories = useSelector(getTerritories())

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      dispatch(
        territoriesActions.fetchTerritories((success) => {
          if (!success) {
            handleClose()
          }
        })
      )
    }
  }, [open])
  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <img src={blueLogo} style={{ height: '50px' }} alt="" />
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          className={classes.close}
          onClick={handleClose}
        >
          <icons.Close />
        </IconButton>
      </DialogContent>
      <DialogContent className={classes.content}>
        <Box>
          <Typography variant="subtitle2">Service Areas</Typography>
          <ul className={classes.list}>
            {territories
              ?.filter((t) => t.title !== 'In-Area')
              ?.sort((a, b) =>
                a.title > b.title ? 1 : b.title > a.title ? -1 : 0
              )
              .filter((terr) => terr.serviceable)
              .map((terr) => (
                <li key={terr.id}>{terr.title}</li>
              ))}
          </ul>
        </Box>
        {isLoaded && territories.length > 0 && (
          <GoogleMap
            center={defaultCenter}
            zoom={xsScreen ? 3 : 4}
            mapContainerStyle={containerStyle}
            // mapContainerClassName={customClass}
            options={{
              clickableIcons: false,
              disableDefaultUI: true,
              draggable: xsScreen,
            }}
          >
            {territories.map((terr) => {
              if (!terr?.latitude || !terr?.longitude || !terr.serviceable)
                return <div key={terr.id}></div>
              return (
                <Marker
                  key={terr.id}
                  position={{ lat: terr?.latitude, lng: terr?.longitude }}
                />
              )
            })}
          </GoogleMap>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ServiceAreasModal
