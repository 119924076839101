import { FC, useEffect } from 'react'
import { Grid, Typography } from '../../../../../../../UI'
import useStyles from './../../../styles'
import {
  getApprovalStep,
  getApprovalValues,
  getContactIndexInEstimate,
  getEstimate,
  getUserIsHomeowner,
  getUserPrimaryEmail,
} from '../../../../../../../../ducks/selectors'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import { useDispatch, useSelector } from 'react-redux'
import BooleanCheckbox from 'components/UI/CustomUI/molecules/BooleanCheckbox'
import Scheduling from '../../../components/Scheduling'
import PropertyStatus from '../../../components/PropertyStatus'
import SmsConsent from '../../../components/SmsConsent'
import AccessDetails from '../../../components/AccessDetails'
import { CONTACT_ROLE, history, isEmpty, objectToUpdateRequest } from 'helpers/index'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions } from 'ducks/actions'
import { EstimateProperties } from 'ducks/types'

const Step1Agent: FC = () => {
  const classes = useStyles()
  const goBack = history.useGoBack()
  const dispatch = useDispatch()
  const { setShowWarnings, setCanGoForward, saveNewValue, showWarnings } =
    useStepperContext()

  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)
  const disableNext = !!estimate?.properties.contacts?.find(
    (contact) => contact.approvalFlowInProgress && contact.email !== userEmail
  )
  const approvalStep = useSelector(getApprovalStep)

  const {
    completedBeforeClosing,
    preferredCompletedOn,
    preferredStartDate,
    smsConsent,
    lockboxConsent,
    role,
    closingDate,
    propertyUnderContract,
    lockbox,
    accessDetails,
    agentIsHomeowner,
  } = useSelector(getApprovalValues())
  const contactIndexEstimate = useSelector(getContactIndexInEstimate(userEmail))

  const isHomeowner = agentIsHomeowner || getUserIsHomeowner() 

  const propertyStatusValidation =
    propertyUnderContract !== undefined && propertyUnderContract !== null
      ? propertyUnderContract
        ? closingDate &&
          completedBeforeClosing !== undefined &&
          completedBeforeClosing !== null
        : true
      : false

  const validate =
    !disableNext &&
    typeof role !== 'undefined' &&
    typeof preferredCompletedOn !== 'undefined' &&
    // typeof preferredStartDate !== 'undefined' &&
    smsConsent &&
    (lockbox || lockboxConsent) &&
    !isEmpty(role) &&
    propertyStatusValidation

  useEffect(() => {
    setCanGoForward(!!validate)
  }, [validate])

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: false })
    )
  }, [])

  const submitStep = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    estimate?.id &&
      dispatch(
        estimateActions.fetchEstimate(estimate?.id, (succ) => {
          if (succ) {
            const approveFlowInProgress = !!estimate?.properties.contacts?.find(
              (contact) =>
                contact.approvalFlowInProgress && contact.email !== userEmail
            )
            !approveFlowInProgress && updateEstimate()
          } else {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
          }
        })
      )
  }

  const updateEstimate = () => {
    const payload: Partial<EstimateProperties> = {
      preferredStartDate,
      preferredCompletedOn,
      propertyUnderContract,
      closingDate,
      completedBeforeClosing,
      lockbox: lockbox ?? '',
      accessDetails,
      approvalStep: approvalStep + 1,
      lockboxConsent,
      smsConsent,
    }
    dispatch(
      estimateActions.updateEstimateByPath(
        [
          {
            path: `/properties/contacts/${contactIndexEstimate}/role`,
            op: 'replace',
            value: role,
          },
          {
            path: `/properties/contacts/${contactIndexEstimate}/approvalFlowInProgress`,
            op: 'replace',
            value: true,
          },
          ...objectToUpdateRequest(payload, '/properties/'),
        ],
        (_succ) => {
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
        }
      )
    )
  }

  return (
    <Grid className={classes.container} spacing={2}>
      <Grid item>
        <Grid item>
          <Scheduling isHomeowner={isHomeowner} />
        </Grid>
      </Grid>

      <Grid className={classes.blueBox}>
        <PropertyStatus isHomeowner={isHomeowner} />
      </Grid>

      {propertyUnderContract && (
        <Grid className={classes.grayBox} xs={6}>
          <Typography className={classes.title}>
            🧰 repairs completion
          </Typography>
          <Typography variant="body1" className={classes.latoPrimaryText}>
            Will the repairs be completed prior to the closing date?
          </Typography>
          <BooleanCheckbox
            onChange={(ev) => {
              saveNewValue('completedBeforeClosing', ev.target.value === 'true')
            }}
            value={completedBeforeClosing}
            error={showWarnings && completedBeforeClosing === undefined}
          />
        </Grid>
      )}

      <SmsConsent />
      <AccessDetails />
      <BottomButtons
        buttonBack={{
          title: 'Back to Estimate',
          action: () => {
            goBack()
          },
          // disable: true,
        }}
        buttonNext={{
          title: 'Payment Information',
          action: () => {
            submitStep()
          },
          disable: !validate,
        }}
      />
    </Grid>
  )
}

export default Step1Agent
