import { FC, useState } from 'react'
import { Dialog, Button, DialogContent, Typography, Box, MultilineText, TextFieldLabel } from '../../../../../UI'
import useStyles from './styles'
import { icons, requestMoreItems } from '../../../../../../assets'
import { RequestMoreItemsModalProps } from './types'
import { CircularProgress, TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { estimateActions } from 'ducks/actions'
import { isMobile } from 'react-device-detect'

const RequestMoreItemsModal: FC<RequestMoreItemsModalProps> = ({
  open,
  setOpen,
}) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [newItems, setNewItems] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setNewItems('')
    setOpen(false)
  }

  const handleSubmit = () => {
    setLoading(true)

    dispatch(estimateActions.requestMoreItems(newItems,
      (succ: boolean) => {
        setLoading(false)
        succ && handleClose()
      }))
  }

  return (
    <Dialog onClose={handleClose} className={styles.modal} open={open} classes={{ paper: styles.root }} >
      <DialogContent className={styles.header}>
        <Box className={styles.inspectionTitleContainer}>
          <img src={requestMoreItems} alt="request more items" />
          <Box>
            <Typography className={styles.title}>REQUEST ESTIMATES FOR ADDITIONAL ITEMS</Typography>
            <Typography className={styles.subtitle}>List additional items with details and descriptions.</Typography>
          </Box>
        </Box>
        <Button onClick={handleClose} className={styles.closeButton}>
          <icons.Close />
        </Button>
      </DialogContent>
      <DialogContent className={styles.body}>
        <Box>
        {/*   <Typography className={styles.inputLabel}>
            List your items
          </Typography> */}
          <MultilineText
            value={newItems}
            onChange={(event: any) => {
              setNewItems(event.target.value)
              // updateNewEstimate('clientNotes', event.target.value)
            }}
            className={styles.itemsInput}
           /*  placeholder={`I need: 
            - A bedroom painted – 13 x 15 x 8, I will provide paint but need primer.​
            - An exterior door replaced​`
            } */
            disabled={loading}
          />
        </Box>
      </DialogContent>
      <DialogContent className={styles.submitContainer}>
        {!isMobile && <Button
          type='submit'
          variant='outlined'
          color='primary'
          className={styles.cancel}
          onClick={handleClose}
        >
          Cancel
        </Button>}
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={styles.submit}
          onClick={handleSubmit}
          disabled={isEmpty(newItems)}
        >
          {loading ? <CircularProgress size={20} /> : 'Request More Items'}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default RequestMoreItemsModal
