import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PrivateLoader } from 'components/templates'
import {
  Typography,
  Grid,
  Autocomplete,
  DatePicker,
  Button,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextFieldLabel,
} from 'components/UI'
import {
  isEmpty,
  getDateProjectOptions,
  USER_TYPE,
  CONTACT_ROLE,
} from 'helpers'
import { icons } from 'assets'

import { getNewEstimateValue, getNewEstimateValues } from 'ducks/selectors'
import { newEstimateActions } from 'ducks/actions'
import { useStepperContext } from 'hooks/useStepperContext'

import useStyles from './styles'
import { NewEstimateType } from 'ducks/newEstimate/types'
import moment from 'moment'
import { history } from '../../../../../../../../helpers'
import { isAuthorized } from '../../../../../../../../ducks/selectors'

const formatUTCTimestampDate = (timestamp: number | null): Date => {
  if (timestamp !== null) {
    const date: Date = new Date(timestamp)
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    )
  }
  return new Date()
}

const Project = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const {
    showWarnings,
    goForward,
    goBack,
    setCanGoForward,
    setShowWarnings,
    saveNewValue,
  } = useStepperContext()
  const dispatch = useDispatch()
  const pushParams = history.usePushParams()

  const clientType = useSelector(getNewEstimateValue('clientType'))
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const {
    repairTimeline,
    propertyToBeSold,
    closingDate,
    isUnderContract,
    represent,
  } = useSelector(getNewEstimateValues())
  const {
    properties: { contacts },
  } = useSelector(getNewEstimateValue('estimate'))

  const authorized = useSelector(isAuthorized)
  const currentDate = moment(new Date()).unix() //moment(Date.now()).unix()
  const SELLER = CONTACT_ROLE.LISTING_AGENT
  const BUYER = CONTACT_ROLE.BUYERS_AGENT
  const MYSELF = CONTACT_ROLE.HOMEOWNER

  const isHomeowner = [USER_TYPE.HOMEOWNER].includes(contacts[0].role)
  const isHomebuyer = [CONTACT_ROLE.HOME_BUYER].includes(contacts[0].role)
  const isOther = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)
  const isNotHomeowner = [
    CONTACT_ROLE.LISTING_AGENT,
    CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
  ].includes(clientType)
  const isNar = useSelector(getNewEstimateValue('isNar'))

  const projectOptions = getDateProjectOptions()

  const showSell = isHomeowner && clientType === CONTACT_ROLE.HOMEOWNER
  const showContract =
    isOther || ((isHomeowner || isHomebuyer) && propertyToBeSold)
  const showDate =
    ((isHomeowner || isHomebuyer) &&
      propertyToBeSold &&
      isUnderContract &&
      clientType === CONTACT_ROLE.HOMEOWNER) ||
    (isOther && isUnderContract)

  const showOther =
    contacts[0].role === CONTACT_ROLE.OTHER &&
    clientType === CONTACT_ROLE.HOMEOWNER

  const validate: boolean =
    !isEmpty(repairTimeline) &&
    (showDate
      ? !isEmpty(closingDate?.toString()) && (closingDate as number) > 0
      : true) &&
    (isNar && isOther ? !isEmpty(represent) : true) &&
    (isOther ? !isEmpty(represent) : true) &&
    (showOther ? !isEmpty(contacts[0].otherRole) : true)

  const handleTriggerContract = (contract: boolean) => {
    if (contract) {
      updateNewEstimate('closingDate', currentDate * 1000)
    } else {
      updateNewEstimate('closingDate', null)
    }
  }

  const updateNewEstimate = useCallback(
    (attr: keyof NewEstimateType, value: any) => {
      saveNewValue(attr, value)
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr,
          value,
        })
      )
    },
    [saveNewValue]
  )

  useEffect(() => {
    setCanGoForward(validate)
  }, [validate])

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  const handleSelect = (date: any) => {
    if (date.startDate) {
      updateNewEstimate('closingDate', date.startDate.valueOf())
    } else {
      updateNewEstimate('closingDate', date.valueOf())
    }
  }

  const updateEstimate = () => {
    if (validate) {
      const properties = {
        propertyToBeSold: propertyToBeSold || false,
        propertyUnderContract: isUnderContract || false,
        contacts,
      } as any
      if (closingDate) {
        const date = new Date(closingDate)
        properties.closingDate =
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
          ) / 1000
      } else if (estimate.properties?.closingDate) {
        // estimate already has closingDate so we need to delete the value
        dispatch(
          newEstimateActions.deleteValueInEstimate(
            '/properties/closingDate',
            (_success, _estimateId) => {}
          )
        )
      }
      dispatch(
        newEstimateActions.updateEstimate(
          {
            properties,
            repairTimeline,
          },
          (success, estimate) => {
            if (success && isOther) {
              dispatch(
                newEstimateActions.replaceValueInEstimate(
                  {
                    path: '/properties/contacts/0/role',
                    value: represent,
                  },
                  (success, estimateId) => {
                    setLoading(false)
                    if (success && estimateId) {
                      goForward()
                    }
                  }
                )
              )
            } else {
              goForward()
            }
          }
        )
      )
    }
  }

  useEffect(() => {
    if (propertyToBeSold !== undefined && !propertyToBeSold) {
      handleTriggerContract(false)
      updateNewEstimate('isUnderContract', false)
    }
  }, [propertyToBeSold])

  useEffect(() => {
    pushParams(`?${authorized ? 'p' : 'o'}=3`)
  }, [])

  const handleChangeRole = (ev: any) => {
    const newContacts = [...contacts]
    newContacts[0].role = ev.target.value
    updateNewEstimate('closingDate', null)

    updateNewEstimate('contacts', newContacts)
  }

  const handleOtherRole = (ev: any) => {
    if (ev.target.value.length > 125) return
    const newContacts = [...contacts]
    newContacts[0].otherRole = ev.target.value

    updateNewEstimate('contacts', newContacts)
  }

  useEffect(() => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'activeStepProgress',
        value: 2,
      })
    )
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'totalStepProgress',
        value: isNotHomeowner ? 4 : 3,
      })
    )
  }, [])

  return (
    <PrivateLoader loading={loading} building="fragment">
      <Grid
        container
        item
        spacing={6}
        className={classes.root}
        aria-label="assignment-inspection-project"
      >
        <Grid item xs={12} className={classes.container}>
          <Typography variant="h4" className={classes.title}>
            few questions about your project
          </Typography>
          <Grid container className={classes.containerForm} spacing={2}>
            {isOther && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>
                  Are you representing the seller or the buyer?
                </Typography>
                <Grid
                  item
                  container
                  style={{ marginTop: '.5rem', gap: '1rem' }}
                  xs={12}
                >
                  <FormControlLabel
                    style={
                      represent === SELLER
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={represent === SELLER}
                        onChange={() => updateNewEstimate('represent', SELLER)}
                        // onChange={() => setRepresent(SELLER)}
                        name="remember"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Seller</Typography>}
                    className={classes.check}
                  />
                  <FormControlLabel
                    style={
                      represent === BUYER
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={represent === BUYER}
                        onChange={() => updateNewEstimate('represent', BUYER)}
                        name="remember"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Buyer</Typography>}
                    className={classes.check}
                  />
                  <FormControlLabel
                    style={
                      represent === MYSELF
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={represent === MYSELF}
                        onChange={() => {
                          updateNewEstimate('represent', MYSELF)
                          updateNewEstimate('clientType', CONTACT_ROLE.HOMEOWNER)
                        }}
                        name="remember"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Myself</Typography>}
                    className={classes.check}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item style={{ width: '100%' }}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>
                  How soon do you need the project completed?
                </Typography>
                <Grid
                  item
                  container
                  style={{ marginTop: '.5rem', gap: '1rem' }}
                  xs={12}
                >
                  {projectOptions.map((option) => (
                    <FormControlLabel
                      style={
                        repairTimeline === option.key
                          ? { borderColor: 'var(--bosscat-blue-600)' }
                          : {}
                      }
                      control={
                        <Checkbox
                          checked={repairTimeline === option.key}
                          onChange={() => {
                            updateNewEstimate('repairTimeline', option.key)
                            // setDate(value.key)
                          }}
                          // onChange={() => setRepresent(SELLER)}
                          name="remember"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body2">{option.value}</Typography>
                      }
                      className={classes.check}
                    />
                  ))}
                </Grid>
              </Grid>

              {/*  <Autocomplete
                label="How soon do you need the project completed?"
                value={
                  repairTimeline
                    ? getDateProjectOptions().find(
                      (r: { key: string }) => r.key === repairTimeline
                    )
                    : undefined
                }
                error={showWarnings && !repairTimeline}
                options={getDateProjectOptions()}
                onChange={(value: any) => {
                  updateNewEstimate('repairTimeline', value.key)
                  // setDate(value.key)
                }}
                titleClassName={classes.subtitle}
                placeholder="Select a date range:"
              /> */}
            </Grid>

            {!isOther && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>
                  Are you a:
                </Typography>
                <Grid
                  item
                  container
                  style={{ gap: '1rem', marginTop: '.5rem' }}
                >
                  <FormControlLabel
                    style={
                      contacts[0].role === CONTACT_ROLE.HOMEOWNER
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={contacts[0].role === CONTACT_ROLE.HOMEOWNER}
                        onChange={handleChangeRole}
                        name="remember"
                        color="primary"
                      />
                    }
                    value={CONTACT_ROLE.HOMEOWNER}
                    label={<Typography variant="body2">Homeowner</Typography>}
                    className={classes.check}
                  />
                  <FormControlLabel
                    style={
                      contacts[0].role === CONTACT_ROLE.HOME_BUYER
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={contacts[0].role === CONTACT_ROLE.HOME_BUYER}
                        onChange={handleChangeRole}
                        name="remember"
                        color="primary"
                      />
                    }
                    value={CONTACT_ROLE.HOME_BUYER}
                    label={<Typography variant="body2">Homebuyer</Typography>}
                    className={classes.check}
                  />
                  <FormControlLabel
                    style={
                      contacts[0].role === CONTACT_ROLE.OTHER
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={contacts[0].role === CONTACT_ROLE.OTHER}
                        onChange={handleChangeRole}
                        name="remember"
                        color="primary"
                      />
                    }
                    value={CONTACT_ROLE.OTHER}
                    label={<Typography variant="body2">Other</Typography>}
                    className={classes.check}
                  />
                </Grid>
              </Grid>
            )}

            {showSell && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>
                  Are you selling your home or getting ready to sell your home?
                </Typography>
                <Grid
                  item
                  container
                  style={{ gap: '1rem', marginTop: '.5rem' }}
                >
                  <FormControlLabel
                    style={
                      !!propertyToBeSold
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={!!propertyToBeSold}
                        onChange={() => {
                          updateNewEstimate(
                            'propertyToBeSold',
                            !propertyToBeSold
                          )
                        }}
                        name="remember"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Yes</Typography>}
                    className={classes.check}
                  />
                  <FormControlLabel
                    style={
                      !propertyToBeSold
                        ? { borderColor: 'var(--bosscat-blue-600)' }
                        : {}
                    }
                    control={
                      <Checkbox
                        checked={!propertyToBeSold}
                        onChange={() => {
                          updateNewEstimate(
                            'propertyToBeSold',
                            !propertyToBeSold
                          )
                        }}
                        name="remember"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">No</Typography>}
                    className={classes.check}
                  />
                </Grid>
              </Grid>
            )}
            {showContract && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>
                  Is this property under contract?
                </Typography>
                <Grid
                  item
                  container
                  style={{ marginTop: '.5rem', gap: '1rem' }}
                >
                  <RadioGroup
                    onChange={(ev) => {
                      handleTriggerContract(Boolean(ev.target.value === 'true'))
                      // ev.target.value === "false" && updateNewEstimate('closingDate', null)
                      updateNewEstimate(
                        'isUnderContract',
                        ev.target.value === 'true'
                      )
                      if(ev.target.value === 'true'){
                        updateNewEstimate(
                          'propertyToBeSold',
                          true
                        )
                      }
                    }}
                    value={isUnderContract}
                    className={classes.propertyContract}
                  >
                    <FormControlLabel
                      style={
                        isUnderContract
                          ? { borderColor: 'var(--bosscat-blue-600)' }
                          : {}
                      }
                      control={
                        <Radio
                          checkedIcon={
                            <icons.CheckBox htmlColor="var(--bosscat-blue-600)" />
                          }
                          icon={<icons.CheckBoxOutlineBlank />}
                        />
                      }
                      checked={isUnderContract}
                      value={true}
                      label={<Typography variant="body2">Yes</Typography>}
                      className={classes.check}
                    />
                    <FormControlLabel
                      style={
                        !isUnderContract
                          ? { borderColor: 'var(--bosscat-blue-600)' }
                          : {}
                      }
                      control={
                        <Radio
                          checkedIcon={
                            <icons.CheckBox htmlColor="var(--bosscat-blue-600)" />
                          }
                          icon={<icons.CheckBoxOutlineBlank />}
                        />
                      }
                      checked={!isUnderContract}
                      value={false}
                      label={<Typography variant="body2">No</Typography>}
                      className={classes.check}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
            {showDate && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1" className={classes.subtitle}>
                  When is the closing date?
                </Typography>
                <DatePicker
                  onChange={handleSelect}
                  value={
                    closingDate !== undefined && closingDate > 0
                      ? formatUTCTimestampDate(closingDate)
                      : null
                  }
                  minDate={new Date()}
                />
              </Grid>
            )}
            {showOther && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>
                  Other:
                </Typography>
                <TextFieldLabel
                  // label='First Name'
                  type="text"
                  value={contacts[0].otherRole}
                  error={showWarnings && !contacts[0].otherRole && showOther}
                  onChange={handleOtherRole}
                  placeholder="Homeowner’s son, Homebuyer’s cousin, etc."
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ display: 'flex', marginTop: '1rem', width: '100%' }}
          justifyContent="space-between"
          className={classes.buttonsContainer}
        >
          <Button
            type="submit"
            variant="outlined"
            size="small"
            className={classes.buttonBack}
            startIcon={<icons.ArrowBack />}
            onClick={goBack}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.buttonNext}
            endIcon={<icons.ArrowForward />}
            disabled={!validate}
            onClick={updateEstimate}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default Project
