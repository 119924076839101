import { FC, useEffect } from 'react'
import { Grid } from '../../../../../../../UI'
import useStyles from '../../../styles'
import {
  getApprovalStep,
  getApprovalValues,
  getContactIndexInEstimate,
  getContactInEstimate,
  getEstimate,
  getEstimateProperties,
  getUserId,
  getUserIsHomeowner,
  getUserPrimaryEmail,
} from '../../../../../../../../ducks/selectors'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import HomeownerInformation from '../../../components/HomeownerInformation'
import SchedulerContact from '../../../components/SchedulerContact'
import PaymentOptions from '../../../components/PaymentOptions'
import ClosingInformation from '../../../components/ClosingInformation'
import Signature from '../../../components/TOSConsent'
import { useDispatch, useSelector } from 'react-redux'
import { EstimateContact } from 'ducks/types'
import {
  APPROVAL_TYPE,
  companyRoles,
  CONTACT_ROLE,
  getDifferenceBetweenObjects,
  history,
  sellerAgentRoles,
  validateEmail,
  validatePhone,
} from 'helpers/index'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions } from 'ducks/actions'
import { diff } from 'deep-object-diff'
import { UpdateRequest } from 'api/types'
import { EstimateType } from 'ducks/estimate/types'

const Step2Agent: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePushForward()

  const { setShowWarnings, setCanGoForward } = useStepperContext()
  const estimate = useSelector(getEstimate())
  const {
    paymentOption,
    companyContact,
    continueWithoutInfo,
    consentTerms,
    propertyUnderContract,
    schedulerSelect,
    scheduler,
    signOk,
    homeowner,
    secondHomeowner,
    homeownersEmailAlreadyExists,
  } = useSelector(getApprovalValues())

  const properties = useSelector(getEstimateProperties())
  const approvalStep = useSelector(getApprovalStep)
  const userEmail = useSelector(getUserPrimaryEmail)
  const userId = useSelector(getUserId)
  const contactInEstimateIndex = useSelector(
    getContactIndexInEstimate(userEmail)
  )
  const contactInEstimate = useSelector(getContactInEstimate(userEmail))

  const schedularContactValidation =
    schedulerSelect !== undefined && schedulerSelect !== null
      ? schedulerSelect === 'other'
        ? !!scheduler?.role &&
          !!scheduler.firstName &&
          !!scheduler.lastName &&
          validateEmail(scheduler.email || '') &&
          validatePhone(scheduler.phone || '')
        : true
      : false

  const homeownerIndex = properties?.contacts?.findIndex(
    (c) => homeowner?.id === c.id
  )

  const closingInformationValidation =
    !!continueWithoutInfo ||
    (!!companyContact?.firstName &&
      validateEmail(companyContact.email || '') &&
      validatePhone(companyContact.phone || ''))

  const homeownerValidation = (homeowner: Partial<EstimateContact>) =>
    homeowner.firstName &&
    homeowner.lastName &&
    validateEmail(homeowner.email || '') &&
    validatePhone(homeowner.phone || '')

  const homeownersValidation =
    homeowner &&
    homeownerValidation(homeowner) &&
    (!secondHomeowner || homeownerValidation(secondHomeowner)) &&
    !homeownersEmailAlreadyExists

  const showPayAtClose = sellerAgentRoles.includes(
    contactInEstimate?.role as CONTACT_ROLE
  )

  const validate =
    homeownersValidation &&
    consentTerms &&
    schedularContactValidation &&
    (paymentOption === 'pay_at_close' || signOk) &&
    (paymentOption === 'pay_at_completion' ||
      !propertyUnderContract ||
      closingInformationValidation)

  useEffect(() => {
    setCanGoForward(!!validate)
  }, [validate])

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  const paymentOptions = [
    {
      label: 'Pay-at-Close',
      key: 'pay_at_close',
    },
    {
      label: 'Pay at Completion of the Project',
      key: 'pay_at_completion',
    },
  ]

  const submitStep = (
    callback: (succ?: boolean, estimate?: EstimateType) => void
  ) => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    const hasOtherScheduler = estimate?.properties?.contacts?.findIndex(
      (c) => c.isScheduler
    )

    const payload: UpdateRequest = []

    //SCHEDULER
    //If we already have a scheduler, we remove it

    if (hasOtherScheduler !== -1) {
      payload.push({
        op: 'replace',
        path: `/properties/contacts/${hasOtherScheduler}/isScheduler`,
        value: false,
      })
    }
    if (schedulerSelect === 'me') {
      payload.push({
        op: 'replace',
        path: `/properties/contacts/${contactInEstimateIndex}/isScheduler`,
        value: true,
      })
    }
    if (schedulerSelect === 'homeowner' && homeownerIndex !== -1) {
      payload.push({
        op: 'replace',
        path: `/properties/contacts/${homeownerIndex}/isScheduler`,
        value: true,
      })
    }

    //HOMEOWNER CONTACT INFORMATION
    if (homeowner) {
      homeowner.approvalType = APPROVAL_TYPE.REQUIRED
      if (schedulerSelect === 'homeowner') {
        homeowner.isScheduler = true
      }
      if (
        paymentOption === 'pay_at_close' &&
        (!closingInformationValidation || !propertyUnderContract)
      ) {
        //If it's pay at close and the property is not under contract, we set the homeowner as payer
        if (homeownerIndex !== -1) {
          payload.push({
            op: 'replace',
            path: `/properties/contacts/${homeownerIndex}/isPayer`,
            value: true,
          })
        }
        homeowner.isPayer = true
      }

      if (
        homeownerIndex?.toString() &&
        homeownerIndex !== -1 &&
        properties?.contacts
      ) {
        const homeownerDifferences: any = diff(
          properties?.contacts[homeownerIndex],
          homeowner
        )
        if (
          homeownerDifferences?.email &&
          homeownerDifferences?.email?.length > 0
        ) {
          const newHomeownerIndex = properties?.contacts?.findIndex(
            (c) => c.email === homeowner.email
          )
          delete homeowner.id
          delete homeowner.contactId

          payload.push(
            {
              op: 'replace',
              path: `/properties/contacts/${homeownerIndex}/approvalType`,
              value: APPROVAL_TYPE.NOT_NEEDED,
            },
            {
              op: 'replace',
              path: `/properties/contacts/${homeownerIndex}/isScheduler`,
              value: false,
            },
            {
              op: 'replace',
              path: `/properties/contacts/${homeownerIndex}/isPayer`,
              value: false,
            }
          )
          if (newHomeownerIndex !== -1) {
            //If the homeowner already exists, we replace the approvalType
            payload.push(
              {
                op: 'replace',
                path: `/properties/contacts/${newHomeownerIndex}/approvalType`,
                value: APPROVAL_TYPE.REQUIRED,
              },
              {
                op: 'replace',
                path: `/properties/contacts/${newHomeownerIndex}/isPayer`,
                value: homeowner.isPayer,
              },
              {
                op: 'replace',
                path: `/properties/contacts/${newHomeownerIndex}/isScheduler`,
                value: homeowner.isScheduler,
              }
            )
          } else {
            payload.push({
              op: 'add',
              path: '/properties/contacts/-',
              value: {
                ...homeowner,
                isPayer:
                  paymentOption === 'pay_at_completion' ||
                  (paymentOption === 'pay_at_close' &&
                    (!closingInformationValidation || !propertyUnderContract)),
                isScheduler: schedulerSelect === 'homeowner',
              },
            })
          }
        } else {
          const homeownerPayload: UpdateRequest = getDifferenceBetweenObjects(
            homeownerDifferences,
            undefined,
            `properties/contacts/${homeownerIndex}`
          )
          payload.push(...homeownerPayload)
        }
      } else {
        payload.push({
          op: 'add',
          path: '/properties/contacts/-',
          value: {
            ...homeowner,
            role: CONTACT_ROLE.HOMEOWNER,
            approvalType: APPROVAL_TYPE.REQUIRED,
          },
        })
      }
    }

    //PAYMENT OPTION
    payload.push({
      op: 'replace',
      path: '/properties/payAtClose',
      value: paymentOption === 'pay_at_close',
    })

    if (paymentOption === 'pay_at_close') {
      const contactIndex = properties?.contacts?.findIndex(
        (c) => c.id === companyContact?.id
      )

      const companyContactDifferences: any =
        contactIndex && companyContact
          ? diff(
              properties?.contacts ? properties?.contacts[contactIndex] : {},
              companyContact
            )
          : null

      //CLOSING INFORMATION
      if (
        companyContact?.id &&
        properties?.contacts?.length &&
        !companyContactDifferences?.email
      ) {
        const companyContactPayload: UpdateRequest =
          getDifferenceBetweenObjects(
            companyContactDifferences,
            undefined,
            `properties/contacts/${contactIndex}`
          )

        payload.push(...companyContactPayload)
      } else if (companyContact?.firstName) {
        const hasOtherPayerIndex = properties?.contacts?.findIndex(
          (c) => c.isPayer
        )
        if (hasOtherPayerIndex !== -1) {
          payload.push({
            op: 'replace',
            path: `/properties/contacts/${hasOtherPayerIndex}/isPayer`,
            value: false,
          })
        }
        delete companyContact?.id
        delete companyContact?.contactId

        payload.push({
          op: 'add',
          path: '/properties/contacts/-',
          value: {
            ...companyContact,
            role: CONTACT_ROLE.OTHER,
            approvalType: APPROVAL_TYPE.PAY_AT_CLOSE,
            isPayer: true,
            phone: companyContact?.phone?.replaceAll('-', ''),
          },
        })
      }
      //Remove homeowner as payer if pay at close is selected
      if (homeowner) {
        homeowner.isPayer = false
      }
    } else {
      const hasAPayAtCloseContact = properties?.contacts?.findIndex(
        (c) => c.approvalType === APPROVAL_TYPE.PAY_AT_CLOSE
      )
      const homeownerIndex = properties?.contacts?.findIndex(
        (c) => c.email === homeowner?.email
      )
      if (hasAPayAtCloseContact !== -1) {
        payload.push({
          op: 'remove',
          path: `/properties/contacts/${hasAPayAtCloseContact}`,
        })
      }
      if (homeownerIndex !== -1) {
        payload.push({
          op: 'replace',
          path: `/properties/contacts/${homeownerIndex}/isPayer`,
          value: true,
        })
      }
    }
    dispatch(
      estimateActions.updateEstimateByPath(payload, (succ, estimate) => {
        callback(succ, estimate)
        dispatch(
          configActions.setConfigValue({ type: 'overlayLoading', value: false })
        )
      })
    )
  }

  const approveEstimate = (estimate: EstimateType) => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )

    const contacts = estimate?.properties?.contacts?.reduce(
      (acc: Partial<EstimateContact>[], contact: Partial<EstimateContact>) => {
        if (contact.email === homeowner?.email) {
          acc.push({
            ...contact,
            approvalType: APPROVAL_TYPE.REQUIRED,
          })
        }
        return acc
      },
      []
    )

    const payload: Partial<EstimateType> = {
      id: estimate?.id,
      properties: {
        address: estimate?.properties?.address,
        payAtClose: estimate?.properties?.payAtClose,
        accessDetails: estimate?.properties?.accessDetails,
        preferredCompletedOn: estimate?.properties?.preferredCompletedOn,
        closingDate: estimate?.properties?.closingDate,
        contacts: contacts,
        userId: userId,
        clientNotes: estimate?.properties?.clientNotes,
        totalValue: estimate?.properties?.totalValue ?? 0,
        approvalStep: 0,
      },
    }
    dispatch(
      estimateActions.approval(
        payload,
        (success: boolean, status: string, payAtClose) => {
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
          if (success) {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
            success && push(`success`)
          }
        }
      )
    )
  }

  const addStep = () => {
    dispatch(
      estimateActions.updateEstimateByPath(
        [
          {
            op: 'replace',
            path: '/properties/approvalStep',
            value: approvalStep + 1,
          },
        ],
        (succ) => {
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
        }
      )
    )
  }

  return (
    <Grid className={classes.container} spacing={2}>
      <HomeownerInformation />
      <SchedulerContact />
      {showPayAtClose && <PaymentOptions paymentOptions={paymentOptions} />}
      {paymentOption === 'pay_at_close' && propertyUnderContract && (
        <ClosingInformation />
      )}
      <Signature showInput={paymentOption !== 'pay_at_close'} />
      <BottomButtons
        buttonBack={{
          title: 'Back',
          action: () => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )
            dispatch(
              estimateActions.updateEstimateByPath(
                [
                  {
                    op: 'replace',
                    path: '/properties/approvalStep',
                    value: approvalStep - 1,
                  },
                ],
                (succ) =>
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
              )
            )
          },
        }}
        buttonNext={{
          title:
            paymentOption === 'pay_at_close'
              ? 'Upload Listing Agreement'
              : 'Send Approval',
          action: () => {
            paymentOption === 'pay_at_close'
              ? submitStep((succ) => {
                  succ && addStep()
                })
              : submitStep((succ, estimate) => {
                  succ && estimate && approveEstimate(estimate)
                })
          },
          disable: !validate,
        }}
      />
    </Grid>
  )
}

export default Step2Agent
