import { Grid, Typography } from '@material-ui/core'
import { getApprovalValues } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './../../styles'
import { FC, useEffect, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { TextFieldLabel } from 'components/UI'
import Checkbox from 'components/UI/CustomUI/molecules/Checkbox'
import { manNotes } from 'assets/index'
import { PHONE_MASK_INPUT, validateEmail, validatePhone } from 'helpers/index'
import { isEmpty } from 'lodash'

export interface ClosingInformationProps {
  showWarning?: boolean
}

const ClosingInformation: FC<ClosingInformationProps> = ({
  showWarning = true,
}) => {
  const classes = useStyles()
  const { showWarnings, saveNewValue } = useStepperContext()
  const {
    companyContact,
    continueWithoutInfo,
  } = useSelector(getApprovalValues())

  useEffect(() => {
    !isEmpty(companyContact) && saveNewValue('continueWithoutInfo', false)
  }, [companyContact])

  return (
    <Grid>
      <Grid
        className={classes.grayBox}
        style={{
          boxShadow:
            '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
          margin: '.1rem',
        }}
      >
        <Typography className={classes.title}>
          📞 closing information
        </Typography>
        <Grid item container spacing={2}>
          <Grid item xs={12} lg={4}>
            <TextFieldLabel
              label="Title Company/Closing Attorney"
              value={companyContact?.firstName || ''}
              error={
                showWarnings &&
                !continueWithoutInfo &&
                !companyContact?.fullName
              }
              onChange={(ev) =>
                saveNewValue('companyContact', {
                  ...companyContact,
                  firstName: ev.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextFieldLabel
              label="Phone Number"
              value={companyContact?.phone || ''}
              error={
                showWarnings &&
                !continueWithoutInfo &&
                !validatePhone(companyContact?.phone || '')
              }
              onChange={(ev) => {
                saveNewValue('companyContact', {
                  ...companyContact,
                  phone: ev.target.value,
                })
              }}
              mask={PHONE_MASK_INPUT}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextFieldLabel
              label="Email"
              value={companyContact?.email || ''}
              error={
                showWarnings &&
                !continueWithoutInfo &&
                !validateEmail(companyContact?.email || '')
              }
              onChange={(ev) =>
                saveNewValue('companyContact', {
                  ...companyContact,
                  email: ev.target.value,
                })
              }
            />
          </Grid>
        </Grid>
       {/*  <Checkbox
          text="I don’t have this information"
          checked={!!continueWithoutInfo}
          onChange={() => {
            saveNewValue('continueWithoutInfo', !continueWithoutInfo)
            !continueWithoutInfo && saveNewValue('companyContact', undefined)
          }}
          background="transparent"
          borderColor="none"
        /> */}
      </Grid>
      {showWarning && (
        <Grid
          item
          container
          className={`${classes.blueBox} ${classes.consentPayAtClose}`}
        >
          <img src={manNotes} alt="Icon" />
          <Typography>
            ℹ️ To qualify for Pay-at-Close, you will be asked to upload the
            listing agreement. Please do not select Pay-at-Close if you do not
            have a listing agreement.
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default ClosingInformation
