import { Button, Grid, InputAdornment, Typography } from '@material-ui/core'
import {
  getApprovalValues,
  getContactInEstimate,
  getUser,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import { FC, useEffect, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import Checkbox from 'components/UI/CustomUI/molecules/Checkbox'
import { NorthEast } from 'assets/index'
import { workOrdersTerms } from 'helpers/index'
import { STextField } from 'components/UI'
import clsx from 'clsx'
import { icons } from 'assets'

export interface TOSConsentProps {
  showInput?: boolean
}

const TOSConsent: FC<TOSConsentProps> = ({ showInput = true }) => {
  const classes = useStyles()
  const user = useSelector(getUser)
  const userEmail = useSelector(getUserPrimaryEmail)
  const [signature, setSignature] = useState('')
  const contactInEstimate = useSelector(getContactInEstimate(userEmail))

  const completeName = contactInEstimate?.firstName + ' ' + contactInEstimate?.lastName

  const ok =
    completeName.replace(/\s/g, '').toUpperCase() ===
    signature.replace(/\s/g, '').toUpperCase()

  const { showWarnings, saveNewValue } = useStepperContext()

  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    window.open(workOrdersTerms)
  }

  const { consentTerms } = useSelector(getApprovalValues())

  useEffect(() => saveNewValue('signOk', ok), [ok])

  return (
    <Grid>
      {showInput && <Typography className={classes.title}> ✍🏻 signature</Typography>}
      {showInput && (
        <Grid item xs={12}>
          <Typography className={classes.latoPrimaryText}>
            Your Signature
          </Typography>
          <STextField
            className={clsx(
              classes.signature,
              showWarnings && !ok ? classes.errorField : {},
              ok ? classes.successField : {}
            )}
            style={{ marginBottom: '1rem' }}
            value={signature}
            error={showWarnings && !ok}
            onChange={(event: any) => {
              setSignature(event.target.value)
            }}
            placeholder={contactInEstimate?.firstName + ' ' + contactInEstimate?.lastName}
            InputProps={
              ok
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <icons.CheckCircle
                          style={{ color: 'var(--completed-color)' }}
                        />
                      </InputAdornment>
                    ),
                  }
                : showWarnings && !ok
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <icons.Error
                          style={{ color: 'var(--expired-color)' }}
                        />
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
        </Grid>
      )}
      <Checkbox
        checked={consentTerms}
        onChange={() => saveNewValue('consentTerms', !consentTerms)}
        name="consent"
        color="primary"
        style={{
          color: showWarnings && !consentTerms ? 'red' : 'primary',
          padding: 0,
          height: '24px',
        }}
        background="#FCFCFC"
        borderColor="#ACCAFC"
        flexDirection="row-reverse"
        justifyContent="space-between"
        text={
          <Typography className={classes.consentText}>
            📝 I understand this signature is an agreement to BOSSCAT
            <Button
              type="submit"
              variant="outlined"
              size="small"
              className={classes.termsButton}
              onClick={handleSubmit}
            >
              <Typography className={classes.terms}>
                Terms and Conditions.
              </Typography>
              <img src={NorthEast} alt="north_east" />
            </Button>
          </Typography>
        }
      />
    </Grid>
  )
}

export default TOSConsent
